import { ENUM_ACTION_TYPES } from '@/enums/Enums'

import { ApplicationAction, FloorPlanState } from '@/types/globalTypes'

const floorPlanReducer = (prevState: FloorPlanState, action: ApplicationAction): FloorPlanState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.DELETE_SECTION: {
            const { sectionId } = action.payload
            const updatedSections = prevState.sections.filter((section) => section.id !== sectionId)
            return { ...prevState, sections: updatedSections }
        }
        case ENUM_ACTION_TYPES.UPDATE_SECTION: {
            const { updatedSectionLocalization } = action.payload
            const updatedSections = prevState.sections.map((section) => {
                if (section.id === updatedSectionLocalization.section_id) {
                    const updatedLocalization = section.sections_localization.map(
                        (localization) => {
                            if (localization.id === updatedSectionLocalization.id) {
                                return { ...updatedSectionLocalization }
                            }
                            return localization
                        }
                    )
                    return { ...section, sections_localization: updatedLocalization }
                }
                return section
            })
            return { ...prevState, sections: updatedSections }
        }
        case ENUM_ACTION_TYPES.INSERT_SECTION: {
            const { newSectionLocalization } = action.payload
            const updatedSections = [...prevState.sections]
            const sectionIndex = updatedSections.findIndex(
                (section) => section.id === newSectionLocalization.section_id
            )

            if (sectionIndex !== -1) {

                const foundSection = updatedSections[sectionIndex]
                const localizationIndex = foundSection.sections_localization.findIndex(
                    (localization) => localization.id === newSectionLocalization.id
                )

                if (localizationIndex !== -1) {
                    foundSection.sections_localization[localizationIndex] = newSectionLocalization
                } else {
                    foundSection.sections_localization.push(newSectionLocalization)
                }
            } else {

                const newSection = {
                    id: newSectionLocalization.section_id,
                    sections_localization: [newSectionLocalization],
                    floor_id: newSectionLocalization.floor_id,
                    styles: {},
                    x_coordinate: 0,
                    y_coordinate: 0

                }
                updatedSections.push(newSection)
            }
            return { ...prevState, sections: updatedSections }
        }
        case ENUM_ACTION_TYPES.DELETE_FLOOR: {
            const { floorId } = action.payload
            const updatedFloors = prevState.floors.filter((floor) => floor.id !== floorId)
            return { ...prevState, floors: updatedFloors }
        }

        case ENUM_ACTION_TYPES.UPDATE_FLOOR: {
            const { updatedFloorLocalization } = action.payload
            const updatedFloors = prevState.floors.map((floor) => {
                if (floor.id === updatedFloorLocalization.floor_id) {
                    const updatedLocalization = floor.floors_localization.map((localization) => {
                        if (localization.id === updatedFloorLocalization.id) {
                            return { ...updatedFloorLocalization }
                        }
                        return localization
                    })
                    return { ...floor, floors_localization: updatedLocalization }
                }
                return floor
            })
            return { ...prevState, floors: updatedFloors }
        }

        case ENUM_ACTION_TYPES.INSERT_FLOOR: {
            const { newFloorLocalization } = action.payload
            const updatedFloors = [...prevState.floors]
            const floorIndex = updatedFloors.findIndex(
                (floor) => floor.id === newFloorLocalization.floor_id
            )

            if (floorIndex !== -1) {

                const foundFloor = updatedFloors[floorIndex]
                const localizationIndex = foundFloor.floors_localization.findIndex(
                    (localization) => localization.id === newFloorLocalization.id
                )

                if (localizationIndex !== -1) {
                    foundFloor.floors_localization[localizationIndex] = newFloorLocalization
                } else {
                    foundFloor.floors_localization.push(newFloorLocalization)
                }
            } else {

                const newFloor = {
                    id: newFloorLocalization.floor_id,
                    floors_localization: [newFloorLocalization],
                }
                updatedFloors.push(newFloor)
            }
            return { ...prevState, floors: updatedFloors }
        }

        default:
            return prevState
    }
}

export default floorPlanReducer
