import { memo, useRef } from 'react'

import { Draggable } from '../../ui/draggable'
import { ResizeHandle } from './ResizeHandle'
import { Axis, EDGE_TYPE } from './ResourceSchedulerComponent'
import { useDraggable } from '@dnd-kit/core'

import { ENUM_MODES } from '@/enums/Enums'

import { cn } from '@/utils/cn'

interface DraggableItemProps {
    id: string
    index: number
    x_coordinate: number
    y_coordinate: number
    children: React.ReactNode
    style?: React.CSSProperties
    onResourceClick?: (id: string) => void
    disableInteractions?: boolean
    mode?: ENUM_MODES
    isActive?: boolean
}

export const DraggableItem = memo(
    ({
        id,
        children,
        index,
        x_coordinate,
        y_coordinate,
        style,
        onResourceClick,
        disableInteractions = false,
        mode = ENUM_MODES.VIEW,
        isActive = false,
    }: DraggableItemProps) => {
        const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null)
        const dragStartedRef = useRef(false)

        const {
            attributes,
            isDragging,
            listeners,
            setNodeRef: setDraggableRef,
            transform,
            active,
        } = useDraggable({
            id,
            data: { index, isResizing: false, x_coordinate, style },
            disabled: mode === ENUM_MODES.VIEW || isActive,
        })

        const isResizing = active?.data.current?.isResizing
        let parentId = active?.data.current?.parentId
        let edge = active?.data.current?.edge

        const handleMouseDown = (e: React.MouseEvent) => {
            if (mode === ENUM_MODES.VIEW) {
                if (onResourceClick) {
                    e.stopPropagation()
                    onResourceClick(id)
                }
                return
            }

            dragStartedRef.current = false
            clickTimeoutRef.current = setTimeout(() => {
                dragStartedRef.current = true
                listeners.onMouseDown(e)
            }, 50)
        }

        const handleMouseUp = (e: React.MouseEvent) => {
            if (mode === ENUM_MODES.VIEW) {
                return // View mode clicks are handled in mouseDown
            }

            if (clickTimeoutRef.current) {
                clearTimeout(clickTimeoutRef.current)
            }

            if (!dragStartedRef.current && !isDragging && onResourceClick) {
                e.stopPropagation()
                onResourceClick(id)
            }

            listeners.onMouseUp?.(e)
        }

        const modifiedListeners =
            mode === ENUM_MODES.VIEW
                ? { onMouseDown: handleMouseDown } // Only keep click handler in view mode
                : {
                      ...listeners,
                      onMouseDown: handleMouseDown,
                      onMouseUp: handleMouseUp,
                  }

        return (
            <Draggable
                ref={setDraggableRef}
                isResizing={isResizing}
                dragging={false}
                listeners={isActive === false ? modifiedListeners : undefined}
                style={{
                    ...style,
                    left: `${x_coordinate}px`,
                }}
                transform={transform}
                axis={Axis.Horizontal}
                data-draggable-id={id}
                className={cn({
                    group: !isDragging,
                    'cursor-move': mode === ENUM_MODES.EDIT && isActive === true,
                    'cursor-pointer': mode === ENUM_MODES.VIEW && onResourceClick,
                    '!cursor-not-allowed': mode === ENUM_MODES.EDIT && isActive,
                })}
                {...attributes}
            >
                {mode === ENUM_MODES.EDIT && !disableInteractions && (
                    <>
                        <ResizeHandle
                            parentId={id}
                            edge="start"
                            className={cn({
                                hidden: isDragging,
                                'bg-success transition-all':
                                    edge === EDGE_TYPE.START && parentId === id,
                            })}
                            transform={transform}
                        />
                        <ResizeHandle
                            parentId={id}
                            edge="end"
                            className={cn({
                                hidden: isDragging,
                                'bg-success transition-all':
                                    edge === EDGE_TYPE.END && parentId === id,
                                'cursor-move': edge === EDGE_TYPE.END && parentId === id,
                            })}
                            transform={transform}
                        />
                    </>
                )}
                {children}
            </Draggable>
        )
    }
)

DraggableItem.displayName = 'DraggableItem'
