/* eslint-disable react-hooks/rules-of-hooks */
import { BookingPageState, ExtendedEventsT, ExtendedTableT, ReservationSubmitValuesТ, TableAvailability, TimePreferencesType, ToastNotificationT } from '@/types/globalTypes'
import { CustomLens } from '../store'
import { Setter } from '@dhmk/zustand-lens'
import { NOTIFICATION_TYPE } from '@/constants/constants'
import useBookingServiceApi from '@/hooks/api/useBookingServiceApi'

import bookingPageReducer from '../reducers/bookingPageReducer'
import { getInitialBookingPageState } from '@/utils/stateUtils'
import { mapErrorCodeToMessage } from '@/utils/functionUtils'
import { ERROR_CODES } from '@/enums/Enums'
import { formatDate } from '@/utils/calendarUtils'


// Define the slice shape with its actions
export type BookingPageSlice = {
    state: BookingPageState
    dispatch: (action: any) => void
    setEvents: Setter<ExtendedEventsT[]>
    setSelectedEvents: Setter<ExtendedEventsT[]>
    setSelectedTable: Setter<ExtendedTableT>
    setSelectedTimePreferences: Setter<{ start_time: string; duration: string, people: number } | null>
    setLoading: Setter<boolean>
    setShowTimePreferences: Setter<boolean>
    setShowFloorPlanMap: Setter<boolean>
    setReservationSteps: Setter<number>
    setConfirmedReservationPopup: Setter<boolean>
    setInitialPageLoad: Setter<boolean>
    setAvailableTablesData: Setter<TableAvailability[]>,
    handleCreateReservation: (values: ReservationSubmitValuesТ) => Promise<void>
    handleTimePreferencesSubmit: (data: TimePreferencesType) => Promise<void>
}


export const bookingPageSlice: CustomLens<BookingPageSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {
    const { createCustomerReservation, getAvailableSlots } = useBookingServiceApi()

    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };
    const setToastNotification = (notification: ToastNotificationT) =>
        getGlobalState().applicationState.setToastNotification(notification)

    const dispatch = (action: any) =>
        set((state) => ({
            ...state,
            state: bookingPageReducer(state.state, action),
        }))

    // Helper function to create state setters
    const createNestedSetter = <K extends keyof BookingPageState>(key: K) =>
        (value: BookingPageState[K]) => set((state) => ({
            ...state,
            state: {
                ...state.state,
                [key]: value,
            },
        }))
    const handleTimePreferencesSubmit = async (data: TimePreferencesType) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations

        setLoading(true)

        const venueId = getGlobalState().applicationState.venueId
        const { availableSlots, error } = await getAvailableSlots({ ...data, venueId: venueId })

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT[error.code] || error.message || TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE
            })
            setLoading(false)
            return;
        }


        get().setAvailableTablesData(availableSlots)
        get().setSelectedTimePreferences(data)
        get().setShowTimePreferences(false)
        getGlobalState().modalState.setOpenCustomerFloorPlanMapPopup(true)
        setLoading(false)

    }

    const handleCreateReservation = async (values: ReservationSubmitValuesТ) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations
        try {
            get().setLoading(true)

            const { error } = await createCustomerReservation(values)
            if (error) {
                throw new Error(error)
            }

            get().setConfirmedReservationPopup(true)
            setToastNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE,
            })
            get().setLoading(false)

        } catch (error) {
            console.error('Error creating reservation:', error)
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: mapErrorCodeToMessage(error.code, TOAST_NOTIFICATIONS_TEXT),
            })
        } finally {
            get().setLoading(false)
        }
    }


    return {
        state: getInitialBookingPageState(),
        dispatch,
        setEvents: createNestedSetter('events'),
        setSelectedEvents: createNestedSetter('selectedEvents'),
        setSelectedTable: createNestedSetter('selectedTable'),
        setSelectedTimePreferences: createNestedSetter('selectedTimePreferences'),
        setLoading: createNestedSetter('loading'),
        setShowTimePreferences: createNestedSetter('showTimePreferences'),
        setShowFloorPlanMap: createNestedSetter('showFloorPlanMap'),
        setReservationSteps: createNestedSetter('reservationSteps'),
        setConfirmedReservationPopup: createNestedSetter('confirmedReservationPopup'),
        setInitialPageLoad: createNestedSetter('initialPageLoad'),
        setAvailableTablesData: createNestedSetter('availableTablesData'),
        handleCreateReservation,
        handleTimePreferencesSubmit
    }
}
