import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { DragMoveEvent, useDndContext, useDndMonitor } from '@dnd-kit/core'
import { differenceInMinutes } from 'date-fns'

import { MILLISECONDS_IN_MINUTE } from '@/constants/constants'

import { cn } from '@/utils/cn'

interface CellInfo {
    id: string
    resourceRowId: string
    startTime: Date
    endTime: Date
}

interface GridCellInfo {
    id: string
    resourceRowId: string
    startTime: Date
    endTime: Date
    x: number
    width: number
}

const GridCell = memo(
    ({
        id,
        width,
        isHovered,
        startTime,
        endTime,
        resourceRowId,
        onCellClick,
        disabled,
    }: GridCellInfo & {
        isHovered: boolean
        onCellClick?: (info: Omit<GridCellInfo, 'x' | 'width'>) => void
        disabled?: boolean
    }) => (
        <div
            key={id}
            onClick={() => !disabled && onCellClick?.({ id, resourceRowId, startTime, endTime })}
            className={cn(
                'grid-cell',
                'border dark:border-gray-900 border-gray-900/25',
                isHovered && '!border-success',
                'hover:!border-success cursor-pointer',
                disabled && 'cursor-not-allowed'
            )}
            style={{
                width: `${width}px`,
                height: '100%',
                display: 'inline-block',
            }}
        />
    )
)

GridCell.displayName = 'GridCell'

interface GridComponentProps {
    startTime: Date
    endTime: Date
    minutesPerGrid: number
    gridSize: number
    resourceRowId: string
    onCellClick?: (cellInfo: CellInfo) => void
    minDuration?: number
}

export const GridComponent = memo(
    ({
        startTime,
        endTime,
        minutesPerGrid,
        gridSize,
        resourceRowId,
        onCellClick,
        minDuration = 60, // Default to 60 minutes
    }: GridComponentProps) => {
        const totalMinutes = differenceInMinutes(endTime, startTime)
        const totalSteps = totalMinutes / minutesPerGrid
        const { active, dragOverlay } = useDndContext()

        const gridCellsInfo = useMemo(() => {
            const cells: GridCellInfo[] = []
            for (let step = 0; step < totalSteps; step += 4) {
                const itemId = `grid-${step}-${resourceRowId}`
                const itemStartTime = new Date(
                    startTime.getTime() + step * minutesPerGrid * MILLISECONDS_IN_MINUTE
                )

                for (let i = 0; i < 4; i++) {
                    const cellId = `${itemId}-${i}-cell`
                    const cellStartTime = new Date(
                        itemStartTime.getTime() + i * minutesPerGrid * MILLISECONDS_IN_MINUTE
                    )
                    const cellEndTime = new Date(
                        cellStartTime.getTime() + minutesPerGrid * MILLISECONDS_IN_MINUTE
                    )
                    const cellStart = step * gridSize + i * gridSize

                    cells.push({
                        id: cellId,
                        resourceRowId,
                        startTime: cellStartTime,
                        endTime: cellEndTime,
                        x: cellStart,
                        width: gridSize,
                    })
                }
            }
            return cells
        }, [totalSteps, gridSize, resourceRowId, startTime, minutesPerGrid])

        const [hoveredCells, setHoveredCells] = useState<Set<string>>(new Set())

        const handleDrag = useCallback(
            (event: DragMoveEvent) => {
                const { active, over, delta } = event
                if (!active || !over || !dragOverlay?.rect) return

                const isDraggingInThisRow =
                    active.data.current?.resourceRowId === resourceRowId ||
                    over.id === resourceRowId

                if (!isDraggingInThisRow) {
                    setHoveredCells(new Set())
                    return
                }

                const x = delta.x ?? 0
                const elementStart = active.data.current.x_coordinate + x
                const elementEnd = elementStart + dragOverlay.rect.width

                if (over.id === resourceRowId) {
                    const newHoveredCells = new Set<string>()

                    gridCellsInfo.forEach((cell) => {
                        const cellEnd = cell.x + cell.width
                        if (
                            (elementStart <= cellEnd && elementEnd >= cell.x) ||
                            (elementStart >= cell.x && elementStart <= cellEnd) ||
                            (elementEnd >= cell.x && elementEnd <= cellEnd)
                        ) {
                            const overlap =
                                Math.min(elementEnd, cellEnd) - Math.max(elementStart, cell.x)
                            if (overlap > gridSize * 0.5) {
                                newHoveredCells.add(cell.id)
                            }
                        }
                    })

                    setHoveredCells(newHoveredCells)
                }
            },
            [dragOverlay, resourceRowId, gridCellsInfo, gridSize]
        )

        useEffect(() => {
            if (!active) {
                setHoveredCells(new Set())
            }
        }, [active])

        useDndMonitor({
            onDragStart: (event) => {
                // Clear highlights if dragging starts in a different row
                if (event.active.data.current?.resourceRowId !== resourceRowId) {
                    setHoveredCells(new Set())
                }
            },
            onDragMove: active ? handleDrag : null,
            onDragOver: active ? handleDrag : null,
            onDragEnd: () => {
                setHoveredCells(new Set())
            },
            onDragCancel: () => {
                setHoveredCells(new Set())
            },
            onDragAbort: () => {
                setHoveredCells(new Set())
            },
        })

        const handleCellClick = (cellInfo: CellInfo) => {
            // Parse the start time into hours and minutes
            const cellStartDate = new Date(cellInfo.startTime)

            // Get hours and minutes for comparison
            const cellHours = cellStartDate.getHours()

            // Don't allow clicks for midnight or after
            if (cellHours === 0 || cellStartDate.getDate() > startTime.getDate()) {
                return
            }

            // Calculate remaining time from this cell to end time
            const remainingMinutes = differenceInMinutes(endTime, cellInfo.startTime)

            // Only allow click if there's enough time remaining for minimum duration
            if (remainingMinutes >= minDuration) {
                onCellClick?.(cellInfo)
            }
        }

        const gridItems = useMemo(() => {
            const items = []
            const queryDateStart = new Date(startTime)

            for (let i = 0; i < totalSteps; i += 4) {
                const id = `grid-${i}-${resourceRowId}`
                const cellsForThisItem = gridCellsInfo.slice(i, i + 4)

                items.push(
                    <div
                        key={id}
                        className="grid-item"
                        style={{
                            width: `${gridSize * 4}px`,
                            height: '100%',
                            display: 'flex',
                        }}
                    >
                        {cellsForThisItem.map((cell) => {
                            const cellDate = new Date(cell.startTime)
                            const cellHours = cellDate.getHours()

                            // Disable cell if it's midnight or after, or if there's insufficient time
                            const isDisabled =
                                cellHours === 0 ||
                                cellDate.getDate() > queryDateStart.getDate() ||
                                differenceInMinutes(endTime, cell.startTime) < minDuration

                            return (
                                <GridCell
                                    key={cell.id}
                                    {...cell}
                                    isHovered={hoveredCells.has(cell.id)}
                                    onCellClick={handleCellClick}
                                    disabled={isDisabled}
                                />
                            )
                        })}
                    </div>
                )
            }
            return items
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            totalSteps,
            gridSize,
            resourceRowId,
            gridCellsInfo,
            hoveredCells,
            onCellClick,
            endTime,
            minDuration,
            startTime,
        ])

        return (
            <div className="relative grid-container" style={{ display: 'flex' }}>
                {gridItems}
            </div>
        )
    }
)

GridComponent.displayName = 'GridComponent'
