import { useDraggable } from '@dnd-kit/core'

import { cn } from '@/utils/cn'

interface ResizeHandleProps {
    edge: 'start' | 'end'
    parentId: string
    className?: string
    transform: { x: number; y: number }
}

export const ResizeHandle = ({ edge, parentId, className, transform }: ResizeHandleProps) => {
    let handleId = `resize-handle-${edge}-${parentId}`
    const { attributes, listeners, setNodeRef, active } = useDraggable({
        id: handleId,

        data: { edge, isResizing: true, parentId: parentId, transform },
    })

    return (
        <div
            ref={setNodeRef}
            className={cn(
                'absolute top-0 bottom-0  w-1/6 max-w-16 h-full rounded-lg cursor-ew-resize z-40',
                { 'left-0': edge === 'start', 'right-0': edge === 'end' },
                {
                    'bg-gradient-to-l from-emerald-500 to-transparent':
                        active?.id === handleId && active?.data?.current?.isResizing && edge === 'end',
                    'bg-gradient-to-r to-transparent from-emerald-500':
                        active?.id === handleId && active?.data?.current?.isResizing && edge === 'start',
                }
            )}
            {...attributes}
            {...listeners}
        >
            <div
                className={cn(
                    'absolute top-0 bottom-0 bg-current w-2  rounded-lg m-2 my-3 cursor-ew-resize  hidden group-hover:block transition-all group-focus:block z-50',
                    { 'left-0': edge === 'start', 'right-0': edge === 'end' },
                    className,
                    {
                        'block bg-green-400':
                            active?.id === handleId && active?.data?.current?.isResizing,
                    }
                )}
            />
        </div>
    )
}
