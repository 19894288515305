import { ENUM_ACTION_TYPES } from '@/enums/Enums'

import { getDefaultExtendedReservationStructure } from '@/utils/stateUtils'

import { ApplicationAction, CustomerState } from '@/types/globalTypes'

const customerReducer = (prevState: CustomerState, action: ApplicationAction): CustomerState => {
    switch (action.type) {
        case ENUM_ACTION_TYPES.UPDATE_CUSTOMER_TABLE: {
            const { updatedTable } = action.payload
            return { ...prevState, tableData: updatedTable }
        }

        case ENUM_ACTION_TYPES.UPDATE_CUSTOMER_RESERVATION: {
            const { updatedReservation } = action.payload

            return { ...prevState, reservation: updatedReservation }
        }

        case ENUM_ACTION_TYPES.DELETE_CUSTOMER_RESERVATION: {
            const newReservationState = getDefaultExtendedReservationStructure()

            return { ...prevState, reservation: newReservationState }
        }

        case ENUM_ACTION_TYPES.GET_CUSTOMER_ORDERS: {
            const { orders } = action.payload
            return { ...prevState, orders }
        }

        case ENUM_ACTION_TYPES.ADD_ORDER: {
            const { newOrder } = action.payload

            const orderExists = prevState.orders.some((order) => order.id === newOrder.id)

            if (orderExists) {
                return prevState
            }

            return {
                ...prevState,
                orders: [...prevState.orders, newOrder],
            }
        }

        case ENUM_ACTION_TYPES.DELETE_CUSTOMER_ORDER: {
            const { orderId } = action.payload
            return {
                ...prevState,
                orders: prevState.orders.filter((order) => order.id !== orderId),
            }
        }
        case ENUM_ACTION_TYPES.UPDATE_CUSTOMER_ORDER: {
            const { updatedOrder } = action.payload

            return {
                ...prevState,
                orders: prevState.orders.map((order) =>
                    order.id === updatedOrder.id ? updatedOrder : order
                ),
            }
        }

        default:
            return prevState
    }
}

export default customerReducer
