import { forwardRef } from 'react'

import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'

import { cn } from '@/utils/cn'

export enum Axis {
    All,
    Vertical,
    Horizontal,
}

interface Props {
    axis?: Axis
    dragOverlay?: boolean
    dragging?: boolean
    handle?: boolean
    label?: string
    listeners?: DraggableSyntheticListeners
    style?: React.CSSProperties
    buttonStyle?: React.CSSProperties
    transform?: Transform | null
    children?: React.ReactNode
    isResizing?: boolean
    className?: string
    disableInteractions?: boolean
}

export const Draggable = forwardRef<HTMLButtonElement, Props>(
    (
        {
            axis,
            dragOverlay,
            dragging,
            handle,
            label,
            listeners,
            transform,
            style,
            className,
            buttonStyle,
            children,
            isResizing,

            disableInteractions = false,
            ...props
        },
        ref
    ) => {
        const touchAction = disableInteractions
            ? 'none'
            : handle
              ? 'none'
              : axis === Axis.Horizontal
                ? 'pan-y'
                : axis === Axis.Vertical
                  ? 'pan-x'
                  : 'none'

        return (
            <div
                {...props}
                className={cn(
                    'relative my-auto flex flex-col items-center transition-transform justify-center duration-250',
                    'cursor-move touch-manipulation',
                    !isResizing && dragging && 'z-50 transition-none -translate-y-1',
                    handle && 'handle',
                    className
                )}
                style={
                    {
                        ...style,
                        '--translate-x': `${dragging ? transform?.x : 0}px`,
                        '--translate-y': `${dragging ? transform?.y : 0}px`,
                        '--scale': !isResizing && dragging ? '1.02' : '1',
                        '--box-shadow':
                            !isResizing && dragging
                                ? '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)'
                                : 'none',
                    } as React.CSSProperties
                }
            >
                <button
                    ref={ref}
                    className={cn(
                        'flex items-center justify-center min-h-[54px] flex-shrink appearance-none outline-none border-0',
                        'transition-shadow rounded-md duration-300',
                        'transform translate-x-[var(--translate-x)] translate-y-[var(--translate-y)] scale-[var(--scale)]',
                        !disableInteractions &&
                            'touch-manipulation cursor-grab focus-visible:shadow-[0_0_0_3px_#4c9ffe]',
                        handle && '[--action-background:rgba(255,255,255,0.1)]',
                        !isResizing && dragging && '!cursor-grab shadow-[var(--box-shadow)]',
                        dragOverlay
                    )}
                    aria-label="Draggable"
                    data-cypress="draggable-item"
                    tabIndex={handle ? -1 : undefined}
                    style={{
                        ...buttonStyle,
                        touchAction,
                    }}
                    {...(disableInteractions ? {} : handle ? {} : listeners)}
                >
                    {children}
                </button>
                {label && (
                    <label
                        className={cn(
                            'block flex-shrink p-[10px] text-center text-base font-light text-[#8d8d8d]',
                            "select-none cursor-[url('/cursor.svg'),auto]",
                            'transition-opacity duration-250',
                            'animate-pulse',
                            dragging && 'animate-none opacity-0'
                        )}
                    >
                        {label}
                    </label>
                )}
            </div>
        )
    }
)

Draggable.displayName = 'Draggable'
