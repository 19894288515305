import React, { useEffect, useMemo, useState } from 'react'

import { addMinutes, differenceInMinutes, format } from 'date-fns'

import { ROW_HEIGHT, ROW_WIDTH } from '@/constants/constants'

import { cn } from '@/utils/cn'

interface TimelineProps {
    queryStartDate: Date
    queryEndDate: Date
    containerWidth?: number
    className?: string
    rowHeight?: number
    rowWidth?: number
    gridSize?: number
    containerRef?: React.RefObject<HTMLDivElement>
}

export const TimelineComponent = ({
    queryStartDate,
    queryEndDate,
    rowHeight = ROW_HEIGHT,
    rowWidth = ROW_WIDTH,
    containerWidth = 240, // 4 hours per screen (60px per hour)
    className,
    gridSize = 60,
    containerRef,
}: TimelineProps) => {
    const [currentTimePosition, setCurrentTimePosition] = useState(() => {
        const minutesFromStart = differenceInMinutes(new Date(), queryStartDate)
        return (minutesFromStart / 60) * containerWidth
    })
    const [currentTime, setCurrentTime] = useState(new Date())
    const timeSlots = useMemo(() => {
        const slots = []
        let currentTime = queryStartDate
        const totalMinutes = differenceInMinutes(queryEndDate, queryStartDate)
        const intervalCount = Math.floor(totalMinutes / 15) + 1

        for (let i = 0; i < intervalCount; i++) {
            slots.push({
                time: format(currentTime, 'HH:mm'),
                isHour: currentTime.getMinutes() === 0,
                date: currentTime,
            })

            currentTime = addMinutes(currentTime, 15)
        }
        return slots
    }, [queryStartDate, queryEndDate])

    // Calculate total width based on duration
    const totalMinutes = differenceInMinutes(queryEndDate, queryStartDate)
    const totalHours = totalMinutes / 60
    const timelineWidth = totalHours * containerWidth

    const isCurrentDateInRange = useMemo(() => {
        const now = new Date()
        return now >= queryStartDate && now <= queryEndDate
    }, [queryStartDate, queryEndDate])


    useEffect(() => {
        if (containerRef?.current && isCurrentDateInRange) {
            const offset = window.innerWidth / 3
            containerRef.current.scrollLeft = currentTimePosition - offset
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCurrentDateInRange])

    useEffect(() => {
        const updateCurrentTime = () => {
            const currentTime = new Date()
            setCurrentTime(currentTime)
            const minutesFromStart = differenceInMinutes(currentTime, queryStartDate)
            const currentTimePosition = (minutesFromStart / 60) * containerWidth
            setCurrentTimePosition(currentTimePosition)
        }

        updateCurrentTime()
        const now = new Date()
        const delay = (60 - now.getSeconds()) * 1000 - now.getMilliseconds()

        const timeoutId = setTimeout(() => {
            updateCurrentTime()
            const intervalId = setInterval(updateCurrentTime, 60000)
            return () => clearInterval(intervalId)
        }, delay)

        return () => clearTimeout(timeoutId)
    }, [queryStartDate, containerWidth])


    return (
        <div className="sticky top-0 z-50 flex dark:bg-gray-800 bg-base-100 dark:border-gray-900 border-gray-900/25">
            <div
                className="sticky top-0 left-0 h-10 bg-inherit z-[999] border-b dark:bg-gray-800 bg-base-100 dark:border-gray-900 border-gray-900/25"
                style={{
                    width: `${rowWidth}px`,
                    minWidth: `${rowWidth}px`,
                }}
            />
            <div className="sticky top-0 z-50 flex dark:bg-gray-800 bg-base-100 dark:border-gray-900 border-gray-900/25">
                <div
                    className={cn(
                        'relative h-10 mb-0 flex border-b dark:bg-gray-800 bg-base-100 dark:border-gray-900 border-gray-900/25',
                        className
                    )}
                    style={{ width: `${timelineWidth}px` }}
                >
                    {isCurrentDateInRange && (
                        <>
                            <div
                                className="absolute top-0 z-50 font-semibold text-center rounded bg-emerald-500 w-14"
                                style={{ left: `${currentTimePosition - 28}px` }}
                            >
                                {format(currentTime, 'HH:mm')}
                            </div>
                            <div
                                className="absolute top-5 z-10 w-0.5 h-screen bg-emerald-400"
                                style={{ left: `${currentTimePosition}px` }}
                            />
                        </>
                    )}

                    {/* Timeline background */}
                    <div className="absolute w-full h-0.5  top-10"></div>

                    {/* Time markers */}
                    <div className="relative w-full">
                        {timeSlots.map((slot, index) => (
                            <div
                                key={`${slot.time}-${index}`}
                                className="absolute flex flex-col justify-center transform -translate-x-1/2"
                                style={{
                                    left: `${(index / (timeSlots.length - 1)) * 100}%`,
                                    top: slot.isHour ? '0' : '24px',
                                }}
                            >
                                {slot.isHour ? (
                                    <>
                                        <span
                                            className={cn('mx-auto text-base text-current', {
                                                'translate-x-1/2': index === 0,
                                                '-translate-x-1/2': index === timeSlots.length - 1,
                                            })}
                                        >
                                            {format(slot.date, 'HH:mm')}
                                        </span>
                                        <div className="h-3 w-0.5 bg-gray-400 mb-1 mx-auto"></div>
                                    </>
                                ) : (
                                    <div className="h-2 w-0.5 bg-gray-300"></div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
