/* eslint-disable react-hooks/rules-of-hooks */
import ordersDashboardReducer from '../reducers/ordersDashboardReducer'
import { Setter } from '@dhmk/zustand-lens'

import {
    ApplicationAction,
    ExtendedOrderT,
    ExtendedReservationT,
    ExtendedTableT,
    ModifiedExtendedMenuItemT,
    ModifiedExtendedReservationT,
    OrdersDashboardState,
    OrderT,
    ReservationSubmitValuesТ,
    ReservationT,
    ToastNotificationT,
} from '@/types/globalTypes'
import { ENUM_ACTION_TYPES, ENUM_MODES, ENUM_ORDER_STATUS_CONSTANTS, ENUM_RESERVATION_ACTIONS } from '@/enums/Enums'
import { getInitialDashboardStateState, getInitialOrderState, getInitialReservationState, getInitialSelectedTableState, isTable, updateState } from '@/utils/stateUtils'
import useBookingServiceApi from '@/hooks/api/useBookingServiceApi'
import { ENUM_ACTIONS, NOTIFICATION_TYPE, ORDER_STATUS_CONSTANTS, STATUS_CONSTANTS } from '@/constants/constants'

import { CustomLens } from '../store'
import useTableServiceApi from '@/hooks/api/useTableServiceApi'
import { PostgrestError } from '@supabase/supabase-js'
import { mapSelectedItems } from '@/utils/functionUtils'

export type OrdersSlice = {
    state: OrdersDashboardState
    dispatch: (action: ApplicationAction) => void
    selectedReservation: ModifiedExtendedReservationT
    initialReservationState: ModifiedExtendedReservationT
    initialSelectedTableState: ExtendedTableT
    initialOrderState: ExtendedOrderT
    selectedOrder: ExtendedOrderT
    selectedTable: ExtendedTableT
    setSelectedReservation: Setter<ModifiedExtendedReservationT>
    setSelectedOrder: Setter<ExtendedOrderT>
    setSelectedTable: Setter<ExtendedTableT>
    setStatus: Setter<ENUM_ORDER_STATUS_CONSTANTS>
    setLoading: Setter<boolean>
    setSelectedMenuItems: Setter<ModifiedExtendedMenuItemT[]>
    setSelectedItemMode: Setter<string>
    setOrderMode: Setter<ENUM_MODES>
    setSelectedOrderSingleItem: Setter<ModifiedExtendedMenuItemT>
    setSearchedOrders: Setter<OrderT[]>
    setSearchValue: Setter<string>
    setInitialPageLoad: Setter<boolean>
    setOffset: Setter<number>
    setIsLoading: Setter<boolean>
    setSearchLoading: Setter<boolean>
    setHasMore: Setter<boolean>
    setReservationAction: Setter<ENUM_RESERVATION_ACTIONS | null>
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>,
    handleGenerateNewPinCode: (static_reservation_id: string) => Promise<void>
    handleAcceptReservationRequest: (reservation: ExtendedReservationT) => Promise<void>
    handleDeclineReservationRequest: (reservation: ExtendedReservationT) => Promise<void>
    handleCreateWalkInReservation: (reservation: ExtendedReservationT) => Promise<void>
    handleReserveTable: (reservation: ExtendedReservationT, tableId: string) => Promise<void>
    handleUnassignTableToWaiter: (table: ExtendedTableT) => Promise<void>
    handleAssignTableToWaiter: (waiterId: string, waiterName: string, table: ExtendedTableT) => Promise<void>
    handleCompleteReservation: () => Promise<void>
    handleUpdateReservation: (values: ReservationSubmitValuesТ) => Promise<void>
    handleCancelReservation: (reservation: ModifiedExtendedReservationT) => Promise<void>;
    handleConfirmReservation: (reservation: ReservationSubmitValuesТ) => Promise<void>;
    handleDisableOrEnableAutoPinRenewal: (value: boolean) => Promise<void>
    handleAcceptOrder: () => Promise<void>
    handleUpdateOrder: (
        newOrder: ExtendedOrderT,
        initialOrderItemsState?: ModifiedExtendedMenuItemT[],
        updateOrderItems?: boolean
    ) => Promise<void>
    handleCreateOrder: (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => Promise<void>
    handleBulkUpdateReservation: (reservations: Partial<ExtendedReservationT>[]) => Promise<void>
    handleReservationActionsPopup: (resetReservation: boolean) => void
    onCancelActionType: () => void
}

const {
    createReservation,
    generateNewPinCode,
    updateReservation,
    bulkUpdateReservation
}
    = useBookingServiceApi()

const {
    assignTableToWaiter,
    createOrder,
    unassignTableFromWaiter,
    updateOrder,
    updateOrderAndOrderItems,
    updateOrders,
} = useTableServiceApi()


export const ordersDashboardSlice: CustomLens<OrdersSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {

    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);


    const createSetter = <K extends keyof OrdersSlice>(key: K) => (value: OrdersSlice[K]) => set((state: OrdersSlice) => ({
        ...state,
        [key]: updateState(state[key], value),
    }));

    const createNestedSetter = <K extends keyof OrdersDashboardState>(key: K) => (value: OrdersDashboardState[K]) => set((state: OrdersSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));


    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };


    const dispatch = (action: ApplicationAction) =>
        set((state) => ({
            ...state,
            state: ordersDashboardReducer(state.state, action),
        }))

    const resetSelectedTableAndReservation = (isReservationToBeRelocated = false) => {

        set((state) => ({
            selectedTable: state.initialSelectedTableState,

        }));

        if (isReservationToBeRelocated === false) {
            set((state) => ({
                selectedReservation: state.initialReservationState,
            }));

        }
    }

    const onCancelActionType = () => {
        get().setReservationAction(null)
        resetSelectedTableAndReservation()
        closeAllPopups()
    }

    const closeAllPopups = () => {
        getGlobalState().modalState.setOpenDrawer(false)
        getGlobalState().modalState.setOpenAssignReservationToTablePopup(false)
        getGlobalState().modalState.setOpenMoveReservationToAnotherTablePopup(false)
        getGlobalState().modalState.setIsReservationToBeRelocated(false)
        getGlobalState().modalState.setOpenViewPopup(false)
        getGlobalState().modalState.setOpenRemoveReservationFromTablePopup(false)
        getGlobalState().modalState.setIsTableToBeReserved(false)
    }

    const handleReservationActionsPopup = (resetReservation = true) => {
        getGlobalState().modalState.setReservationActionsPopup(false)
        if (resetReservation) {
            setTimeout(() => {
                get().setSelectedReservation(get().initialReservationState)
            }, 200)
        }

    }
    const handleBulkUpdateReservation = async (reservations: ExtendedReservationT[]) => {
        setLoading(true);

        const baseReservations = reservations.map((reservation) => {
            const { hasPendingOrder, pin_code, table, ...rest } = reservation

            return rest as ReservationT
        })

        const { data, error } = await bulkUpdateReservation(baseReservations)


        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: getGlobalState().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            setLoading(false)
            return
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATIONS,
            payload: { updatedReservations: data },
        })
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: getGlobalState().applicationState.translations.TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE,
        })
        setLoading(false)

        getGlobalState().modalState.setOpenAssignReservationToTablePopup(false);
        getGlobalState().modalState.setOpenMoveReservationToAnotherTablePopup(false);
        get().setReservationAction(null)
        getGlobalState().modalState.setOpenActivateSelectedReservationPopup(false);
        getGlobalState().modalState.setOpenDeactivateSelectedReservationPopup(false);
        getGlobalState().modalState.setReservationActionsPopup(false);
        getGlobalState().modalState.setOpenCancelReservationPopup(false);
        getGlobalState().modalState.setOpenCompleteReservationFromTablePopup(false);
    }

    const handleDisableOrEnableAutoPinRenewal = async (value: boolean) => {
        const selectedTable = get().selectedTable;
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const { pin_code, ...restOfData } = selectedTable.reservation;

        const { data, error } = await updateReservation({
            id: restOfData.id,
            skip_auto_pin_renewal: value,
        });

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }

        set((state) => ({
            selectedTable: {
                ...state.selectedTable,
                reservation: {
                    ...state.selectedTable.reservation,
                    skip_auto_pin_renewal: data[0].skip_auto_pin_renewal,
                },
            },
        }));

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        });

        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openAutoRenewalPinCodePopup: false,
                }
            },
        }));
    };

    const handleGenerateNewPinCode = async (static_reservation_id: string) => {
        setLoading(true);
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        const [data, error] = await generateNewPinCode(static_reservation_id);

        if (error) {

            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);

        } else {

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        id: state.selectedTable.static_reservation_id,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    },
                },
            }));
            setToastNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE || 'Success',
            });
            setLoading(false);
            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        openGenerateNewPinCode: false,

                    }
                },
            }));

        }
    };

    const handleAcceptReservationRequest = async (reservation: ExtendedReservationT) => {
        setLoading(true);
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const { error } = await updateReservation({
            id: reservation.id,
            actions: ENUM_ACTIONS.REQUEST_ACCEPTED,
            waiter_responded: true,
        });
        if (error) {
            setToastNotification({ type: NOTIFICATION_TYPE.ERROR, message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE });
            setLoading(false);
            return;
        }
        set((state) => ({
            selectedTable: {
                ...state.selectedTable,
                reservation: {
                    ...state.selectedTable.reservation,
                    actions: '',
                },
            },
        }));
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: {
                updatedReservation: {
                    ...reservation,
                    id: reservation.id,
                    actions: '',
                    waiter_responded: true,
                },
            },
        });
        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openHandleAcceptReservationRequestPopup: false,
                    openConfirmActionPopup: false,
                }
            },
        }));
    };

    const handleDeclineReservationRequest = async (reservation: ExtendedReservationT) => {
        setLoading(true);

        const { error } = await updateReservation({
            id: reservation.id,
            actions: ENUM_ACTIONS.REQUEST_DECLINED,
            waiter_responded: true,
        });
        if (error) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
        } else {
            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        actions: '',
                    },
                },
            }));
            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: {
                    updatedReservation: {
                        ...reservation,
                        id: reservation.id,
                        actions: '',
                        waiter_responded: true,
                    },
                },
            });
            setLoading(false);
            setGlobalState((state) => ({
                modalState: {
                    ...state.modalState,
                    state: {
                        ...state.modalState.state,
                        openHandleDeclineReservationRequestPopup: false,
                        openConfirmActionPopup: false,
                    }
                },
            }));
        }
    };

    const handleCancelReservation = async (reservation: ModifiedExtendedReservationT) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const reservationData = {
            id: reservation.id,
            name_of_the_person: reservation.name_of_the_person,
            email: reservation.email,
            phone_number: reservation.phone_number,
            people: reservation.people,
            status: STATUS_CONSTANTS.DECLINED,
            extra_information: reservation.extra_information,
            is_active: false,
        };

        const { data, error } = await updateReservation(reservationData);

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        });


        getGlobalState().modalState.setOpenCancelReservationPopup(false);
        getGlobalState().modalState.setReservationActionsPopup(false);

        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
    };

    const handleConfirmReservation = async (reservation: ReservationSubmitValuesТ) => {
        const { data, error } = await updateReservation(reservation)

        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            return
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        })

        getGlobalState().modalState.setOpenEditReservationPopup(false);
        getGlobalState().modalState.setOpenViewReservationPopup(false);

    }

    const handleUpdateReservation = async (values: ReservationSubmitValuesТ) => {
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const reservationData = {
            id: values.id,
            name_of_the_person: values.fullName,
            email: values.email,
            phone_number: values.phone,
            date_of_reservation: values.date_of_reservation,
            people: values.people,
            status: values.status,
            extra_information: values.extra_information,
            start_time: values.start_time,
            end_time: values.end_time,
            is_active: values.is_active,
        };

        const { data, error } = await updateReservation(reservationData);
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            return;
        }
        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: data[0] },
        });
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });

        getGlobalState().modalState.setOpenEditReservationPopup(false);
        getGlobalState().modalState.setReservationActionsPopup(false);
        getGlobalState().modalState.setOpenDeactivateSelectedReservationPopup(false);
    };


    const handleCompleteReservation = async () => {
        setLoading(true);
        const { error } = await updateReservation({
            id: get().selectedReservation.id,
            status: STATUS_CONSTANTS.COMPLETED,
            waiter_responded: true,
            table_id: null
        });
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        if (error) {

            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.DELETE_RESERVATION,
            payload: { deletedReservationId: get().selectedReservation.id },
        });

        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openCompleteReservationFromTablePopup: false,
                    openViewPopup: false,
                }
            },
        }));
        resetSelectedTableAndReservation();
    };

    const handleReserveTable = async (reservation: ExtendedReservationT, tableId: string) => {
        setLoading(true);
        const updatedReservation = {
            id: reservation.id,
            people: reservation.people,
            extra_information: reservation.extra_information,
            start_time: reservation.start_time,
            end_time: reservation.end_time,
            table_id: tableId,
        };

        const { error } = await updateReservation(updatedReservation);
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            resetSelectedTableAndReservation();
            setLoading(false);
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
            payload: { updatedReservation: { ...reservation, ...updatedReservation } },
        });
        get().setReservationAction(null)
        resetSelectedTableAndReservation();
        closeAllPopups();
        setLoading(false);
    };

    const handleAssignTableToWaiter = async (waiterId: string, waiterName: string, table: ExtendedTableT) => {
        setLoading(true);
        const excludeOrderByStatus = [
            ORDER_STATUS_CONSTANTS.PAID,
            ORDER_STATUS_CONSTANTS.SERVED,
            ORDER_STATUS_CONSTANTS.REFUNDED,
        ];

        // Get the IDs of the orders for this table
        const orderIdsForTable = get().state.orders
            .filter(
                (order) =>
                    (order.reservation.table !== null &&
                        isTable(order.reservation.table) &&
                        order.reservation.table.id === table.id) ||
                    (order.reservation.id === table.static_reservation_id &&
                        !excludeOrderByStatus.includes(order.status))
            )
            .map((order) => order.id);

        const error = await assignTableToWaiter(waiterId, waiterName, table.id);
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            });
            setLoading(false);
            return;
        }
        // Only update orders in the database if there are any associated with this table
        if (orderIdsForTable.length > 0) {
            const [updatedOrders, updateOrdersError] = await updateOrders(orderIdsForTable, {
                waiter: waiterId,
                waiter_name: getGlobalState().applicationState.profile.full_name,
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_ORDERS,
                payload: {
                    updatedOrders: updatedOrders as OrderT[],
                },
            })

            if (updateOrdersError) {
                setToastNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
                });
                setLoading(false);
                return;
            }
        }

        dispatch({
            type: ENUM_ACTION_TYPES.ASSIGN_TABLE_TO_WAITER,
            payload: {
                waiterId,
                table,
                waiterName: getGlobalState().applicationState.profile.full_name,
                orderIdsForTable,
            },
        });

        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openAssignWaiterToTablePopup: false,
                }
            },
        }));
        if (getGlobalState().modalState.state.openViewTableOrdersPopup === false) {
            resetSelectedTableAndReservation(getGlobalState().modalState.state.isReservationToBeRelocated);
        }
    };

    const handleCreateWalkInReservation = async (values: ExtendedReservationT) => {
        setLoading(true);
        const reservationData = {
            date_of_reservation: values.date_of_reservation,
            people: values.people,
            status: STATUS_CONSTANTS.CONFIRMED,
            extra_information: values.extra_information,
            start_time: values.start_time,
            end_time: values.end_time,
            is_temporary: true,
            is_walk_in: true,
            table_id: get().selectedTable.id,
            venue_id: values.venue_id
        } as ReservationT;

        const [createReservationError, data] = await createReservation(reservationData);

        if (createReservationError) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        dispatch({
            type: ENUM_ACTION_TYPES.INSERT_RESERVATION,
            payload: { newReservation: data[0] },
        });

        if (get().selectedTable.id !== '') {

            set((state) => ({
                selectedTable: { ...state.selectedTable, reservation: data[0] },
            }));
        }
        setLoading(false);
        getGlobalState().modalState.setOpenCreateWalkInReservationPopup(false);

        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_SUCCESS_MESSAGE || 'Success',
        });
    };

    const handleUnassignTableToWaiter = async (table: ExtendedTableT) => {
        setLoading(true);
        const excludeOrderByStatus = [
            ORDER_STATUS_CONSTANTS.PAID,
            ORDER_STATUS_CONSTANTS.SERVED,
            ORDER_STATUS_CONSTANTS.REFUNDED,
        ];

        // Get the IDs of the orders for this table
        const orderIdsForTable = get().state.orders
            .filter(
                (order) =>
                    (order.reservation.table !== null &&
                        isTable(order.reservation.table) &&
                        order.reservation.table.id === table.id) ||
                    (order.reservation.id === table.static_reservation_id &&
                        !excludeOrderByStatus.includes(order.status))
            )
            .map((order) => order.id);

        const error = await unassignTableFromWaiter(table.id);
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
        if (error) {

            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }
        // Only update orders in the database if there are any associated with this table
        if (orderIdsForTable.length > 0) {
            const [updatedOrders, updateOrdersError] = await updateOrders(orderIdsForTable, {
                waiter: null,
                waiter_name: null,
            });

            if (updateOrdersError) {
                setToastNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE || 'Error',
                });
                setLoading(false);
                return; // Exit the function early if there was an error updating orders
            }
        }

        dispatch({
            type: ENUM_ACTION_TYPES.ASSIGN_TABLE_TO_WAITER,
            payload: {
                waiterId: null,
                table,
                waiterName: null,
                orderIdsForTable,
            },
        });
        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    openUnassignWaiterToTablePopup: false,
                }
            },
        }));
        resetSelectedTableAndReservation();
    };

    const handleCreateOrder = async (
        selectedMenuItems: ModifiedExtendedMenuItemT[],
        totalPrice: number,
        specialRequests: string
    ) => {
        setLoading(true);

        const profile = getGlobalState().applicationState.profile || { id: '', full_name: 'Unknown', venue_id: '' };
        const selectedTable = get().selectedTable;
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        const orderItems = mapSelectedItems(selectedMenuItems);
        const mappedData = {
            order_items: orderItems,
            total_price: totalPrice,
            status: ORDER_STATUS_CONSTANTS.ACCEPTED,
            reservation_id: selectedTable.reservation.id || selectedTable.static_reservation_id,
            waiter: profile.id,
            waiter_name: profile.full_name,
            special_requests: specialRequests,
            guest_id: selectedTable.reservation.guest_id,
            venue_id: profile.venue_id,
        } as ExtendedOrderT;

        const [data, error] = await createOrder(mappedData);

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        setLoading(false);
        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    createOrderPopup: false,
                    openMenuPopup: false,
                    orderActionsPopup: false,
                }
            },
        }));
        set((state) => ({
            state: {
                ...state.state,
                selectedMenuItems: [],
            },
        }));

        dispatch({
            type: ENUM_ACTION_TYPES.INSERT_ORDER,
            payload: {
                newOrder: data,
            },
        });
    };

    const handleUpdateOrder = async (
        newOrder: ExtendedOrderT,
        initialOrderItemsState: ModifiedExtendedMenuItemT[] = null,
        updateOrderItems = false
    ) => {
        setLoading(true);

        let data: ExtendedOrderT, error: PostgrestError;
        const { reservation, ...restOfOrder } = newOrder;
        const profile = getGlobalState().applicationState.profile || { id: '', full_name: 'Unknown' };
        const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};

        if (updateOrderItems) {
            let { orderData, orderError } = await updateOrderAndOrderItems(
                {
                    ...restOfOrder,
                    last_updated_by: profile.id,
                },
                initialOrderItemsState
            );
            data = orderData;
            error = orderError;
        } else {
            const { orderData, orderError } = await updateOrder(restOfOrder);
            data = orderData as ExtendedOrderT;
            error = orderError;
        }

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
            setLoading(false);
            return;
        }

        setGlobalState((state) => ({
            modalState: {
                ...state.modalState,
                state: {
                    ...state.modalState.state,
                    selectedOrderPopup: false,
                    orderActionsPopup: false,
                    openCancelOrderPopup: false,
                }
            },
        }));

        set((state) => ({
            state: {
                ...state.state,
                selectedMenuItems: [],
                selectedItemMode: ENUM_MODES.CREATE,
                orderMode: ENUM_MODES.CREATE,
            },
        }));

        dispatch({
            type: ENUM_ACTION_TYPES.UPDATE_ORDER,
            payload: {
                updatedOrder: data,
            },
        });

        setLoading(false);
    };

    const handleAcceptOrder = async () => {
        try {
            const profile = getGlobalState().applicationState.profile || { id: '', full_name: 'Unknown' };
            const selectedTable = get().selectedTable;
            const selectedOrder = get().selectedOrder;


            if (selectedTable.waiter === null) {
                await get().handleAssignTableToWaiter(profile.id, profile.full_name, selectedTable);
                set((state) => ({
                    selectedTable: {
                        ...state.selectedTable,
                        waiter: profile.id,
                        waiter_name: profile.full_name,
                    },
                }));

            }
            await handleUpdateOrder({
                ...selectedOrder,
                id: selectedOrder.id,
                status: ORDER_STATUS_CONSTANTS.ACCEPTED,
                waiter: profile.id,
                waiter_name: profile.full_name,
                last_updated_by: profile.id,
            });
        } catch (error) {
            const { TOAST_NOTIFICATIONS_TEXT } = getGlobalState().applicationState.translations || {};
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT?.GENERIC_ERROR_MESSAGE || 'Error',
            });
        }
    };

    return {
        state: getInitialDashboardStateState(),
        selectedReservation: getInitialReservationState(),
        selectedTable: getInitialSelectedTableState(),
        initialSelectedTableState: getInitialSelectedTableState(),
        selectedOrder: getInitialOrderState(),
        initialReservationState: getInitialReservationState(),
        initialOrderState: getInitialOrderState(),
        setToastNotification: () => { },
        dispatch: dispatch,
        setSelectedReservation: createSetter('selectedReservation'),
        setSelectedOrder: createSetter('selectedOrder'),
        setSelectedTable: createSetter('selectedTable'),
        setStatus: createNestedSetter('status'),
        setLoading: createNestedSetter('loading'),
        setSelectedMenuItems: createNestedSetter('selectedMenuItems'),
        setSelectedItemMode: createNestedSetter('selectedItemMode'),
        setOrderMode: createNestedSetter('orderMode'),
        setSelectedOrderSingleItem: createNestedSetter('selectedOrderSingleItem'),
        setSearchedOrders: createNestedSetter('searchedOrders'),
        setSearchValue: createNestedSetter('searchValue'),
        setInitialPageLoad: createNestedSetter('initialPageLoad'),
        setOffset: createNestedSetter('offset'),
        setIsLoading: createNestedSetter('isLoading'),
        setSearchLoading: createNestedSetter('searchLoading'),
        setReservationAction: createNestedSetter('reservationAction'),
        setHasMore: createNestedSetter('hasMore'),
        handleAcceptReservationRequest,
        handleAssignTableToWaiter,
        handleUnassignTableToWaiter,
        handleDeclineReservationRequest,
        handleGenerateNewPinCode,
        handleCompleteReservation,
        handleUpdateReservation,
        handleCancelReservation,
        handleConfirmReservation,
        handleCreateWalkInReservation,
        handleReserveTable,
        handleUpdateOrder,
        handleAcceptOrder,
        handleCreateOrder,
        handleDisableOrEnableAutoPinRenewal,
        handleBulkUpdateReservation,
        handleReservationActionsPopup,
        onCancelActionType
    }

}
