import { ProfileT, ToastNotificationT } from '@/types/globalTypes'
import { ModalSlice, modalsSlice } from './slices/modalsSlice'
import { ordersDashboardSlice, OrdersSlice } from './slices/ordersDashboardSlice'
import { reservationsDashboardSlice, ReservationsSlice } from './slices/reservationsDashboardSlice'
import { tablePageSlice, TTablePageSlice } from './slices/tablePageSlice'
import { lens, withLenses, LensContext as OriginalLensContext, Setter, Getter, LensMeta, } from '@dhmk/zustand-lens'
import { createStore, StoreApi } from 'zustand'
import { devtools } from 'zustand/middleware'
import { TranslationModule } from '@/i18n'
import { ApplicationStateSlice, applicationStateSlice } from './slices/applicationStateSlice'
import { User } from '@supabase/supabase-js'
import { defaultLayoutPageSlice, DefaultLayoutPageSlice } from './slices/defaultLayoutPageSlice'
import { bookingPageSlice, BookingPageSlice } from './slices/bookingPageSlice'

export type ApplicationStore = {
    reservationsDashboard: ReservationsSlice,
    tablePage: TTablePageSlice,
    ordersDashboard: OrdersSlice,
    modalState: ModalSlice,
    applicationState: ApplicationStateSlice,
    defaultLayoutPage: DefaultLayoutPageSlice,
    bookingPage: BookingPageSlice,
}

export type CustomLensContext<T> = OriginalLensContext<T, ApplicationStore> & {
    setToastNotification: React.Dispatch<React.SetStateAction<ToastNotificationT>>,
    translations: TranslationModule,
    profile: ProfileT,
    user: User,
    venueId: string,
}

export type CustomLens<T extends LensMeta<unknown, unknown>> = (set: Setter<T>, get: Getter<T>, api: StoreApi<ApplicationStore>, context: CustomLensContext<T>, setGlobal: Setter<ApplicationStore>, getGlobal: Getter<ApplicationStore>, initialState?: Partial<ApplicationStore>) => T


export const createApplicationStore = (initialState: Partial<ApplicationStore>) => createStore(
    devtools(
        withLenses((setGlobalState: Setter<Partial<ApplicationStore>>, getGlobalState: Getter<ApplicationStore>) => ({

            applicationState: lens((subSet, subGet: Getter<ApplicationStateSlice>, api, context: CustomLensContext<ApplicationStateSlice>) => applicationStateSlice(subSet, subGet, api, context, setGlobalState, getGlobalState, initialState)),

            reservationsDashboard: lens((subSet, subGet: Getter<ReservationsSlice>, api, context: CustomLensContext<ReservationsSlice>) => reservationsDashboardSlice(subSet, subGet, api, context, setGlobalState, getGlobalState)),

            tablePage: lens((subSet, subGet: Getter<TTablePageSlice>, api, context: CustomLensContext<TTablePageSlice>) => tablePageSlice(subSet, subGet, api, context, setGlobalState, getGlobalState)),

            ordersDashboard: lens((subSet, subGet: Getter<OrdersSlice>, api, context: CustomLensContext<OrdersSlice>) => ordersDashboardSlice(subSet, subGet, api, context, setGlobalState, getGlobalState)),

            modalState: lens((subSet, subGet: Getter<ModalSlice>, api, context: CustomLensContext<ModalSlice>) => modalsSlice(subSet, subGet, api, context, setGlobalState, getGlobalState)),

            defaultLayoutPage: lens((subSet, subGet: Getter<DefaultLayoutPageSlice>, api, context: CustomLensContext<DefaultLayoutPageSlice>) => defaultLayoutPageSlice(subSet, subGet, api, context, setGlobalState, getGlobalState)),

            bookingPage: lens((subSet, subGet: Getter<BookingPageSlice>, api, context: CustomLensContext<BookingPageSlice>) => bookingPageSlice(subSet, subGet, api, context, setGlobalState, getGlobalState)),
        })),
        {
            name: 'ApplicationStore',
        }
    )
)
