import {
    ModifiedExtendedMenuItemT,
    ModalState,
    BasicButtonProps,
    ExtendedTableT,
    ExtendedOrderT,
    ProfileT,
    ExtendedReservationT,
} from '@/types/globalTypes';
import { ENUM_MODES } from '@/enums/Enums';
import { CustomLens } from '../store';
import { getInitialModalState, updateState } from '@/utils/stateUtils';
import { Setter } from '@dhmk/zustand-lens';


export type ModalSlice = {
    state: ModalState;
    setOpenActivateSelectedReservationPopup: Setter<boolean>;
    setOpenDeactivateSelectedReservationPopup: Setter<boolean>;
    setOpenViewPopup: Setter<boolean>;
    setIsTableToBeReserved: Setter<boolean>;
    setOpenViewTableOrdersPopup: Setter<boolean>;
    setIsReservationToBeRelocated: Setter<boolean>;
    setOrderActionsPopup: Setter<boolean>;
    setSelectedOrderPopup: Setter<boolean>;
    setCreateOrderPopup: Setter<boolean>;
    setRemoveItemPopup: Setter<boolean>;
    setMenuAddonsPopup: Setter<boolean>;
    setOpenCancelOrderPopup: Setter<boolean>;
    setOpenCreateWalkInReservationPopup: Setter<boolean>;
    setOpenAssignReservationToTablePopup: Setter<boolean>;
    setOpenAssignWaiterToTablePopup: Setter<boolean>;
    setOpenUnassignWaiterToTablePopup: Setter<boolean>;
    setOpenConfirmActionPopup: Setter<boolean>;
    setOpenHandleAcceptReservationRequestPopup: Setter<boolean>;
    setOpenHandleDeclineReservationRequestPopup: Setter<boolean>;
    setOpenMoveReservationToAnotherTablePopup: Setter<boolean>;
    setOpenRemoveReservationFromTablePopup: Setter<boolean>;
    setOpenCompleteReservationFromTablePopup: Setter<boolean>;
    setOpenGenerateNewPinCode: Setter<boolean>;
    setOpenAutoRenewalPinCodePopup: Setter<boolean>;
    setOpenScanReservationPopup: Setter<boolean>;
    setOpenScanErrorPopup: Setter<boolean>;
    setOpenDrawer: Setter<boolean>;
    setSelectedTable: Setter<ExtendedTableT | null>;
    setOrders: Setter<ExtendedOrderT[]>;
    setButtons: Setter<BasicButtonProps[]>;
    setSelectedOrder: Setter<ExtendedOrderT | null>;
    setSelectedMenuItems: Setter<ModifiedExtendedMenuItemT[]>;
    setLoading: Setter<boolean>;
    setProfile: Setter<ProfileT | null>;
    setSelectedOrderSingleItem: Setter<ModifiedExtendedMenuItemT>;
    setSelectedItemMode: Setter<ENUM_MODES>;
    setOpenMenuPopup: Setter<boolean>;
    setOrderMode: Setter<ENUM_MODES>;
    setSelectedReservation: Setter<ExtendedReservationT | null>;
    setSingleOrderItemActionButtonsArray: Setter<BasicButtonProps[]>;
    setVenuePrefix: Setter<string>;
    setReserveTablePopupButtons: Setter<BasicButtonProps[]>;
    setAssignTableToWaiterButtons: Setter<BasicButtonProps[]>;
    setUnassignTableToWaiterButtons: Setter<BasicButtonProps[]>;
    setAcceptTableActionButtons: Setter<BasicButtonProps[]>;
    setHandleAcceptReservationRequestButtonsArray: Setter<BasicButtonProps[]>;
    setHandleDeclineReservationRequestButtonsArray: Setter<BasicButtonProps[]>;
    setRemoveReservationPopupButtons: Setter<BasicButtonProps[]>;
    setCompleteReservationPopupButtons: Setter<BasicButtonProps[]>;
    setGenerateNewPinCodeButtons: Setter<BasicButtonProps[]>;
    setScanErrorMessage: Setter<string>;
    setOpenViewReservationPopup: Setter<boolean>;
    setOpenCancelReservationPopup: Setter<boolean>;
    setOpenDeleteReservationPopup: Setter<boolean>;
    setOpenEditReservationPopup: Setter<boolean>;
    setOpenCreateReservationPopup: Setter<boolean>;
    setOpenReservationScheduler: Setter<boolean>;
    setOpenEventsDetailPopup: Setter<boolean>;
    setOpenCreateTablePopup: Setter<boolean>;
    setOpenCopyDefaultTableLayoutPopup: Setter<boolean>;
    setOpenEditTablePopup: Setter<boolean>;
    setOpenDeleteTablePopup: Setter<boolean>;
    setPaymentActionsPopup: Setter<boolean>;
    setOpenActionsPopup: Setter<boolean>;
    setOpenCancelActionPopup: Setter<boolean>;
    setOpenCompleteReservationPopup: Setter<boolean>;
    setOpenCheckoutPopup: Setter<boolean>;
    setCheckOutStep: Setter<number>;
    setOpenViewTablePopup: Setter<boolean>;
    setOpenPrintQrCodePopup: Setter<boolean>;
    setOpenCreateFloorPopup: Setter<boolean>;
    setOpenEditFloorPopup: Setter<boolean>;
    setOpenDeleteFloorPopup: Setter<boolean>;
    setOpenCreateSectionPopup: Setter<boolean>;
    setOpenEditSectionPopup: Setter<boolean>;
    setOpenDeleteSectionPopup: Setter<boolean>;
    setReservationActionsPopup: Setter<boolean>;
    setOpenCustomerFloorPlanMapPopup: Setter<boolean>;
}





export const modalsSlice: CustomLens<ModalSlice> = (set, get, api, ctx, setGlobal, getGlobal) => {


    const createNestedSetter = <K extends keyof ModalState>(key: K) => (value: ModalState[K]) => set((state: ModalSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));

    return {
        state: getInitialModalState(),
        setOpenCustomerFloorPlanMapPopup: createNestedSetter('openCustomerFloorPlanMapPopup'),
        setReservationActionsPopup: createNestedSetter('openReservationActionsPopup'),
        setOpenViewPopup: createNestedSetter('openViewPopup'),
        setOpenViewTableOrdersPopup: createNestedSetter('openViewTableOrdersPopup'),
        setOrderActionsPopup: createNestedSetter('orderActionsPopup'),
        setSelectedOrderPopup: createNestedSetter('selectedOrderPopup'),
        setCreateOrderPopup: createNestedSetter('createOrderPopup'),
        setRemoveItemPopup: createNestedSetter('removeItemPopup'),
        setMenuAddonsPopup: createNestedSetter('menuAddonsPopup'),
        setOpenCancelOrderPopup: createNestedSetter('openCancelOrderPopup'),
        setOpenCreateWalkInReservationPopup: createNestedSetter('openCreateWalkInReservationPopup'),
        setOpenAssignReservationToTablePopup: createNestedSetter('openAssignReservationToTablePopup'),
        setOpenAssignWaiterToTablePopup: createNestedSetter('openAssignWaiterToTablePopup'),
        setOpenUnassignWaiterToTablePopup: createNestedSetter('openUnassignWaiterToTablePopup'),
        setOpenConfirmActionPopup: createNestedSetter('openConfirmActionPopup'),
        setOpenHandleAcceptReservationRequestPopup: createNestedSetter('openHandleAcceptReservationRequestPopup'),
        setOpenHandleDeclineReservationRequestPopup: createNestedSetter('openHandleDeclineReservationRequestPopup'),
        setOpenMoveReservationToAnotherTablePopup: createNestedSetter('openMoveReservationToAnotherTablePopup'),
        setOpenRemoveReservationFromTablePopup: createNestedSetter('openRemoveReservationFromTablePopup'),
        setOpenCompleteReservationFromTablePopup: createNestedSetter('openCompleteReservationFromTablePopup'),
        setOpenGenerateNewPinCode: createNestedSetter('openGenerateNewPinCode'),
        setOpenAutoRenewalPinCodePopup: createNestedSetter('openAutoRenewalPinCodePopup'),
        setOpenScanReservationPopup: createNestedSetter('openScanReservationPopup'),
        setOpenScanErrorPopup: createNestedSetter('openScanErrorPopup'),
        setOpenDrawer: createNestedSetter('openDrawer'),
        setSelectedTable: createNestedSetter('selectedTable'),
        setOrders: createNestedSetter('orders'),
        setButtons: createNestedSetter('buttons'),
        setSelectedOrder: createNestedSetter('selectedOrder'),
        setSelectedMenuItems: createNestedSetter('selectedMenuItems'),
        setLoading: createNestedSetter('loading'),
        setProfile: createNestedSetter('profile'),
        setSelectedOrderSingleItem: createNestedSetter('selectedOrderSingleItem'),
        setSelectedItemMode: createNestedSetter('selectedItemMode'),
        setOpenMenuPopup: createNestedSetter('openMenuPopup'),
        setOrderMode: createNestedSetter('orderMode'),
        setSelectedReservation: createNestedSetter('selectedReservation'),
        setSingleOrderItemActionButtonsArray: createNestedSetter('singleOrderItemActionButtonsArray'),
        setVenuePrefix: createNestedSetter('venuePrefix'),
        setReserveTablePopupButtons: createNestedSetter('reserveTablePopupButtons'),
        setAssignTableToWaiterButtons: createNestedSetter('assignTableToWaiterButtons'),
        setUnassignTableToWaiterButtons: createNestedSetter('unassignTableToWaiterButtons'),
        setAcceptTableActionButtons: createNestedSetter('acceptTableActionButtons'),
        setHandleAcceptReservationRequestButtonsArray: createNestedSetter('handleAcceptReservationRequestButtonsArray'),
        setHandleDeclineReservationRequestButtonsArray: createNestedSetter('handleDeclineReservationRequestButtonsArray'),
        setRemoveReservationPopupButtons: createNestedSetter('removeReservationPopupButtons'),
        setCompleteReservationPopupButtons: createNestedSetter('completeReservationPopupButtons'),
        setGenerateNewPinCodeButtons: createNestedSetter('generateNewPinCodeButtons'),
        setIsReservationToBeRelocated: createNestedSetter('isReservationToBeRelocated'),
        setIsTableToBeReserved: createNestedSetter('isTableToBeReserved'),
        setOpenEventsDetailPopup: createNestedSetter('openEventsDetailPopup'),
        setOpenCreateReservationPopup: createNestedSetter('openCreateReservationPopup'),
        setOpenEditReservationPopup: createNestedSetter('openEditReservationPopup'),
        setOpenDeleteReservationPopup: createNestedSetter('openDeleteReservationPopup'),
        setOpenCancelReservationPopup: createNestedSetter('openCancelReservationPopup'),
        setOpenViewReservationPopup: createNestedSetter('openViewReservationPopup'),
        setScanErrorMessage: createNestedSetter('scanErrorMessage'),
        setOpenDeleteTablePopup: createNestedSetter('openDeleteTablePopup'),
        setOpenEditTablePopup: createNestedSetter('openEditTablePopup'),
        setOpenCopyDefaultTableLayoutPopup: createNestedSetter('openCopyDefaultTableLayoutPopup'),
        setOpenCreateTablePopup: createNestedSetter('openCreateTablePopup'),
        setPaymentActionsPopup: createNestedSetter('paymentActionsPopup'),
        setOpenActionsPopup: createNestedSetter('openActionsPopup'),
        setOpenCancelActionPopup: createNestedSetter('openCancelActionPopup'),
        setOpenCompleteReservationPopup: createNestedSetter('openCompleteReservationPopup'),
        setOpenCheckoutPopup: createNestedSetter('openCheckoutPopup'),
        setCheckOutStep: createNestedSetter('checkOutStep'),
        setOpenViewTablePopup: createNestedSetter('openViewTablePopup'),
        setOpenPrintQrCodePopup: createNestedSetter('openPrintQrCodePopup'),
        setOpenCreateFloorPopup: createNestedSetter('openCreateFloorPopup'),
        setOpenEditFloorPopup: createNestedSetter('openEditFloorPopup'),
        setOpenDeleteFloorPopup: createNestedSetter('openDeleteFloorPopup'),
        setOpenCreateSectionPopup: createNestedSetter('openCreateSectionPopup'),
        setOpenEditSectionPopup: createNestedSetter('openEditSectionPopup'),
        setOpenDeleteSectionPopup: createNestedSetter('openDeleteSectionPopup'),
        setOpenActivateSelectedReservationPopup: createNestedSetter('openActivateSelectedReservationPopup'),
        setOpenDeactivateSelectedReservationPopup: createNestedSetter('openDeactivateSelectedReservationPopup'),
        setOpenReservationScheduler: createNestedSetter('openReservationScheduler'),
    }
};
