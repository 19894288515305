import React, { memo } from 'react'

import { DraggableItem } from './DraggableItemComponent'
import { GridComponent } from './GridComponent'
import { Resource } from './ResourceSchedulerComponent'
import { useDroppable } from '@dnd-kit/core'
import { ENUM_MODES } from '@/enums/Enums'
import { cn } from '@/utils/cn'

interface ResourceRow {
    id: string
    section?: string
}
interface ResourceRowComponentProps {
    resourceRow: ResourceRow
    resourceRowComponent: (resourceRow: ResourceRow) => React.ReactNode
    resourceItems: Resource[]
    resourceItemComponent: (resource: Resource) => React.ReactNode
    queryStartDate: Date
    queryEndDate: Date
    gridSize: number
    minutesPerGrid: number
    onCellClick?: (cellInfo: { startTime: Date; endTime: Date; resourceRowId: string }) => void
    onResourceClick?: (id: string) => void
    rowHeight?: string
    mode?: ENUM_MODES
    minDuration?: number
}

const ResourceRowComponent = memo(
    ({
        resourceRow,
        resourceRowComponent,
        resourceItems,
        resourceItemComponent,
        queryStartDate,
        queryEndDate,
        gridSize,
        minutesPerGrid,
        onCellClick,
        onResourceClick,
        rowHeight,
        mode = ENUM_MODES.VIEW,
        minDuration = 60,
    }: ResourceRowComponentProps) => {
        const { setNodeRef } = useDroppable({
            id: resourceRow.id,
            disabled: mode === ENUM_MODES.VIEW
        })


        return (
            <div className="flex">
                <div
                    className="sticky left-0 z-50 bg-base-100"
                    style={{ height: rowHeight }}
                >
                    {resourceRowComponent(resourceRow)}
                </div>
                <div
                    className="relative flex"
                    key={resourceRow.id}
                    ref={setNodeRef}
                    style={{
                        height: rowHeight,
                        position: 'relative',
                        minHeight: rowHeight
                    }}
                >
                    <GridComponent
                        resourceRowId={resourceRow.id}
                        startTime={queryStartDate}
                        endTime={queryEndDate}
                        gridSize={gridSize}
                        minutesPerGrid={minutesPerGrid}
                        onCellClick={onCellClick}
                        minDuration={minDuration}
                    />
                    {resourceItems.map((resource, index) => {
                        if (resource.resourceRowId === resourceRow.id) {
                            return (
                                <DraggableItem
                                    key={resource.id}
                                    id={resource.id}
                                    index={index}
                                    x_coordinate={resource.x_coordinate}
                                    y_coordinate={resource.y_coordinate}
                                    style={{
                                        ...resource.styles,
                                        position: 'absolute',
                                        left: `${resource.x_coordinate}px`,
                                        minWidth: '96px',
                                        width: resource.width,
                                    }}
                                    onResourceClick={onResourceClick}
                                    disableInteractions={mode === ENUM_MODES.VIEW}
                                    mode={mode}
                                    isActive={resource.is_active}
                                >
                                    {resourceItemComponent({
                                        ...resource,
                                        classNames: cn(
                                            'cursor-move',
                                            mode === ENUM_MODES.VIEW && 'cursor-default'
                                        ),
                                    })}
                                </DraggableItem>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
)
ResourceRowComponent.displayName = 'ResourceRowComponent'

export default ResourceRowComponent
