import {
    ApplicationAction,
    InitReservationsDashboardDataPayload,
    ExtendedReservationT, BookingPageState
} from '@/types/globalTypes'
import { ACTION_TYPES } from '@/constants/constants'

import { findTableReservationData, getDefaultExtendedReservationStructure, getDefaultTableStructure } from '@/utils/stateUtils'
import { getTranslation } from '@/utils/functionUtils'
import { normalizeTimeToMinutes } from '@/components/shared/ResourceSchedulerComponent/helperFunctions'

const bookingPageReducer = (
    state: BookingPageState,
    action: ApplicationAction
): BookingPageState => {
    switch (action.type) {
        case ACTION_TYPES.INIT_DATA: {
            const payload = action.payload as InitReservationsDashboardDataPayload
            const { sections, floors, tables, reservationData, selectedLanguage } = payload

            // Map reservations with their tables
            const mappedReservations = reservationData.map((reservation) => {
                const tableData = tables.find(
                    (table) => table.static_reservation_id === reservation.id || table.id === reservation.table_id
                )

                return {
                    ...reservation,
                    table: tableData || getDefaultTableStructure(),
                }
            }).toSorted((a, b) => {
                const aStart = normalizeTimeToMinutes(a.start_time)
                const bStart = normalizeTimeToMinutes(b.start_time)
                return aStart - bStart
            }) as ExtendedReservationT[]

            // Map tables with localization and reservations
            const mappedTables = tables.map((table) => {
                const tableSection = sections.find((section) => section.id === table.section_id)
                const tableFloor = floors.find((floor) => floor.id === tableSection?.floor_id)

                const sectionLocalization = getTranslation(
                    tableSection?.sections_localization,
                    selectedLanguage
                )

                const floorLocalization = getTranslation(
                    tableFloor?.floors_localization,
                    selectedLanguage
                )

                const tableReservationData = findTableReservationData(mappedReservations, table)

                return {
                    ...table,
                    reservation: tableReservationData || getDefaultExtendedReservationStructure(),
                    section: sectionLocalization?.translated_name || '',
                    floor: floorLocalization?.translated_name || '',
                }
            }).toSorted((a, b) => {
                const nameA = a.table_name.toLowerCase()
                const nameB = b.table_name.toLowerCase()
                const matchA = RegExp(/\d+/).exec(nameA)
                const matchB = RegExp(/\d+/).exec(nameB)
                const numA = matchA ? parseInt(matchA[0], 10) : 0
                const numB = matchB ? parseInt(matchB[0], 10) : 0
                return numA !== numB ? numA - numB : nameA.localeCompare(nameB)
            })

            return {
                ...state,
                floors,
                sections,
                tablesData: mappedTables,
                reservations: mappedReservations,
                initialPageLoad: false
            }
        }

        default:
            return state
    }
}

export default bookingPageReducer
