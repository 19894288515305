import React from 'react'

import { useSelect } from 'downshift'

import { cn } from '@/utils/cn'

import { DropdownOptionT } from '@/types/globalTypes'

type DropdownComponentProps<T extends DropdownOptionT> = {
    options: T[]
    selectedValue?: string
    handleSelect: (selectedItem: T) => void
    containerClassName?: string
    selectedValueClass?: string
    className?: string
    dropdownClassName?: string
    disabled?: boolean
    placeholder?: string
    required?: boolean
    showDropdownLabel?: boolean
}

const DropdownComponent = <T extends DropdownOptionT>({
    options, // This should be an array of objects with 'value' and optionally 'id' properties
    selectedValue,
    handleSelect, // This function will receive the entire selected item as an argument
    containerClassName,
    className,
    dropdownClassName,
    selectedValueClass,
    disabled = false,
    placeholder = '',
    required = false,
    showDropdownLabel = false,
}: DropdownComponentProps<T>) => {
    const defaultContainerClassName =
        'input rounded-lg input-bordered rounded-lg m-auto text-lg font-semibold transition-none bg-gray-100 dropdown dark:bg-base-100 dropdown-bottom relative transition-all'
    const defaultClassName =
        'transition-all rounded-lg select select-bordered flex m-1 my-auto text-lg font-semibold btn btn-ghost hover:bg-transparent normal-case '
    const defaultDropdownClassName =
        'w-full shadow-lg bg-base-100 rounded-box border border-slate-600 dark:border-slate-100 !border-opacity-20 flex flex-col max-h-96 overflow absolute overflow-y-auto z-[9999] mt-1 animate-fade animate-duration-150'

    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: options,
        itemToString: (item) => (item ? item.value : ''),
        onSelectedItemChange: ({ selectedItem }) => {
            handleSelect(selectedItem)
        },
    })

    return (
        <div
            className={cn(containerClassName || defaultContainerClassName)}
            aria-disabled={disabled}
        >
            <div
                {...getToggleButtonProps()}
                className={`${className || defaultClassName} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                aria-disabled={disabled}
            >
                <span className={cn('self-center w-full truncate', selectedValueClass)}>
                    {selectedValue || placeholder}
                </span>
            </div>
            <ul
                onClick={(e) => e.stopPropagation()}
                {...getMenuProps()}
                className={`${dropdownClassName || defaultDropdownClassName} ${!isOpen || disabled ? 'hidden' : ''}`}
            >
                {!disabled &&
                    isOpen &&
                    options.map((option, index) => (
                        <li
                            onClick={(e) => e.stopPropagation()}
                            className={cn(
                                'h-10 justify-center cursor-pointer !text-center leading-10 align-middle',
                                {
                                    'bg-slate-200 dark:bg-slate-500': highlightedIndex === index,
                                    'font-bold': selectedItem?.value === option?.value,
                                },
                                option.className
                            )}
                            key={option.id || option.value}
                            {...getItemProps({ item: option, index })}
                        >
                            {option.icon ? option.icon : null}

                            {(showDropdownLabel ? option.label : option.value) || option.name}
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default DropdownComponent
