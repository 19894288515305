import { useMemo, useState } from 'react'

import { useDefer } from './useDefer'
import { endOfDay } from 'date-fns'

import { useApplicationContext } from '@/context/ApplicationContext'

import { calculateQueryDates, calculateQueryDatesWithoutCurrentTime } from '@/utils/functionUtils'

import { WorkingHoursT } from '@/types/globalTypes'

// Define a generic type for your hook with a default value
const useQueryDateRange = <T extends boolean = false>(
    selectedDate?: Date,
    returnAsDate: T = false as T,
    withCurrentTime: boolean = true
) => {
    const { applicationState } = useApplicationContext()
    const { active_working_hours, working_hours } = applicationState.venue
    const [dateToday, setDateToday] = useState(new Date())
    const dayOfWeek = selectedDate ? selectedDate.getDay() : dateToday.getDay()

    // We deferentially set a new day at the end of the current one
    useDefer(endOfDay(dateToday), () => {
        if (!selectedDate) {
            setDateToday(new Date())
        }
    })

    const currentTime = new Date().toLocaleTimeString('en-GB', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

    const { starting_hours, ending_hours, is_active } = selectedDate
        ? working_hours[dayOfWeek]
        : active_working_hours

    const [queryStartDate, queryEndDate] = withCurrentTime
        ? calculateQueryDates(selectedDate || dateToday, currentTime, starting_hours, ending_hours, true) : calculateQueryDatesWithoutCurrentTime(
            selectedDate || dateToday,
            starting_hours,
            ending_hours,
            returnAsDate
        ) as [Date, Date]

    // Utilize conditional types for dynamic return types
    const dateRangeDetails = useMemo(
        () => ({
            starting_hours,
            ending_hours,
            queryStartDate: returnAsDate ? new Date(queryStartDate) : queryStartDate,
            queryEndDate: returnAsDate ? new Date(queryEndDate) : queryEndDate,
            dateToday: selectedDate || dateToday,
            is_active,
            currentTime,
            dayOfWeek,
            working_hours,
        }),
        [
            currentTime,
            dateToday,
            dayOfWeek,
            ending_hours,
            is_active,
            queryEndDate,
            queryStartDate,
            returnAsDate,
            selectedDate,
            starting_hours,
            working_hours,
        ]
    )

    type TReturnDetails = {
        starting_hours: string
        ending_hours: string
        queryStartDate: T extends true ? Date : string
        queryEndDate: T extends true ? Date : string
        dateToday: Date
        is_active: boolean
        currentTime: string
        dayOfWeek: number
        working_hours: WorkingHoursT[]
    }

    return dateRangeDetails as TReturnDetails
}

export default useQueryDateRange
