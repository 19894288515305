const STATUS_CONSTANTS = {
    CONFIRMED: 'confirmed',
    PENDING: 'pending',
    DECLINED: 'declined',
    // SEATED: "seated",
    COMPLETED: 'completed',
}
const ORDER_STATUS_CONSTANTS = {
    ACCEPTED: 'accepted',
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    READY: 'ready',
    SERVED: 'served',
    CANCELLED: 'cancelled',
    REFUNDED: 'refunded',
    PAID: 'paid',
    NONE: 'none',
}

const RECEIPT_STATUS_CONSTANTS = {
    PARTIAL_REFUND: 'partial_refund',
    PENDING_REFUND: 'pending_refund',
    REFUND_FAILED: 'refund_failed',
    REFUNDED: 'refunded',
}
const REFUND_FAILED_REASONS = {
    CHARGE_FOR_PENDING_REFUND_DISPUTED: 'charge_for_pending_refund_disputed',
    DECLINED: 'declined',
    EXPIRED_OR_CANCELED_CARD: 'expired_or_canceled_card',
    INSUFFICIENT_FUNDS: 'insufficient_funds',
    LOST_OR_STOLEN_CARD: 'lost_or_stolen_card',
    MERCHANT_REQUEST: 'merchant_request',
    UNKNOWN: 'unknown',
}

const NOTIFICATION_TYPE = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
    ALERT: 'alert',
    PROMISE: 'promise',
}
const TABS_CONSTANTS = {
    RESERVATIONS: 0,
    TABLES: 1,
    ORDERS: 2,
    INVENTORY: 3,
}
const MODE_CONSTANTS = {
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',
    DUPLICATE: 'duplicate',
}
const LOCATION_CONSTANTS = {
    // ORIGIN: window?.location.origin
}
const ENUM_ROLES = {
    WAITER: 'WAITER',
    BARMAN: 'BARMAN',
    HOST: 'HOST',
    MANAGER: 'MANAGER',
    OWNER: 'OWNER',
    CUSTOMER: 'CUSTOMER',
    KITCHEN_STAFF: 'KITCHEN_STAFF',
}

const ENUM_PATHNAMES = {
    BOOKING: '/',
    RESERVATIONS: '/reservations/[id]',
    RESERVATIONS_DASHBOARD: '/reservations-dashboard',
    ORDERS_DASHBOARD: '/orders-dashboard',
    ADMIN_DASHBOARD: '/admin-dashboard',
    LOGIN: '/login',
    CATCH_ALL: '/[...slug]',
}

const ENUM_ACTIONS = {
    REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
    REQUEST_DECLINED: 'REQUEST_DECLINED',
    PAY_WITH_CASH: 'PAY_WITH_CASH',
    PAY_WITH_POS: 'PAY_WITH_POS',
    CALL_WAITER: 'CALL_WAITER',
    UNATTENDED_TABLE_CALL_WAITER: 'UNATTENDED_TABLE_CALL_WAITER',
    PAY_WITH_CASH_REQUEST_ACCEPTED: 'PAY_WITH_CASH_REQUEST_ACCEPTED',
    PAY_WITH_POS_REQUEST_ACCEPTED: 'PAY_WITH_POS_REQUEST_ACCEPTED',
    CALL_WAITER_REQUEST_ACCEPTED: 'CALL_WAITER_REQUEST_ACCEPTED',
    PAY_WITH_CASH_REQUEST_DECLINED: 'PAY_WITH_CASH_REQUEST_DECLINED',
    PAY_WITH_POS_REQUEST_DECLINED: 'PAY_WITH_POS_REQUEST_DECLINED',
    CALL_WAITER_REQUEST_DECLINED: 'CALL_WAITER_REQUEST_DECLINED',
    FINISH_RESERVATION: 'FINISH_RESERVATION',
    ACTION_CANCELED: 'ACTION_CANCELED',
    ORDER_CREATED: 'ORDER_CREATED',
    UNATTENDED_TABLE_ORDER_CREATED: 'UNATTENDED_TABLE_ORDER_CREATED',
    ORDER_CANCELLED: 'ORDER_CANCELLED',
    ORDER_PENDING: 'ORDER_PENDING',
    ORDER_IN_PROGRESS: 'ORDER_IN_PROGRESS',
    ORDER_READY: 'ORDER_READY',
    ORDER_PAID: 'ORDER_PAID',
    ORDER_REFUNDED: 'ORDER_REFUNDED',
    ORDER_ACCEPTED: 'ORDER_ACCEPTED',
    TABLE_ASSIGNED: 'TABLE_ASSIGNED',
    TABLE_REMOVED: 'TABLE_REMOVED',
    TABLE_CHANGED: 'TABLE_CHANGED',
    ATTENDED_TABLE_CHANGED_TO_UNATTENDED: 'ATTENDED_TABLE_CHANGED_TO_UNATTENDED',
    UNATTENDED_TABLE_CHANGED_TO_UNATTENDED: 'UNATTENDED_TABLE_CHANGED_TO_UNATTENDED',
    UNATTENDED_TABLE_CHANGED_TO_ATTENDED: 'UNATTENDED_TABLE_CHANGED_TO_ATTENDED',
    TABLE_CHANGED_NEW_ASSIGNED_WAITER: 'TABLE_CHANGED_NEW_ASSIGNED_WAITER',
    TABLE_CHANGED_FROM_ANOTHER_WAITER: 'TABLE_CHANGED_FROM_ANOTHER_WAITER',
    UNATTENDED_TABLE: 'UNATTENDED_TABLE',
    UNATTENDED_TABLE_ASSIGNED: 'UNATTENDED_TABLE_ASSIGNED',
    UNATTENDED_TABLE_REMOVED: 'UNATTENDED_TABLE_REMOVED',
    UNATTENDED_TABLE_CHANGED: 'UNATTENDED_TABLE_CHANGED',
    WAITER_RESERVATION_COMPLETED: 'WAITER_RESERVATION_COMPLETED',
    RESERVATION_COMPLETED: 'RESERVATION_COMPLETED',
    ITEM_HIGH_THRESHOLD: 'ITEM_HIGH_THRESHOLD',
    ITEM_LOW_THRESHOLD: 'ITEM_LOW_THRESHOLD',
    ITEM_ZERO_THRESHOLD: 'ITEM_ZERO_THRESHOLD',
}

const NOTIFICATIONS_COMPONENT_ICON_COLORS = {
    [ENUM_ACTIONS.REQUEST_ACCEPTED]: 'stroke-green-500',
    [ENUM_ACTIONS.REQUEST_DECLINED]: 'stroke-red-500',
    [ENUM_ACTIONS.PAY_WITH_CASH]: 'stroke-green-500',
    [ENUM_ACTIONS.PAY_WITH_POS]: 'stroke-sky-500',
    [ENUM_ACTIONS.CALL_WAITER]: 'stroke-yellow-500',
    [ENUM_ACTIONS.UNATTENDED_TABLE_CALL_WAITER]: 'stroke-yellow-500',
    [ENUM_ACTIONS.PAY_WITH_CASH_REQUEST_ACCEPTED]: 'stroke-green-500',
    [ENUM_ACTIONS.PAY_WITH_POS_REQUEST_ACCEPTED]: 'stroke-info',
    [ENUM_ACTIONS.CALL_WAITER_REQUEST_ACCEPTED]: 'stroke-green-500',
    [ENUM_ACTIONS.PAY_WITH_CASH_REQUEST_DECLINED]: 'stroke-red-500',
    [ENUM_ACTIONS.PAY_WITH_POS_REQUEST_DECLINED]: 'stroke-red-500',
    [ENUM_ACTIONS.CALL_WAITER_REQUEST_DECLINED]: 'stroke-red-500',
    [ENUM_ACTIONS.FINISH_RESERVATION]: 'stroke-green-500',
    [ENUM_ACTIONS.ACTION_CANCELED]: 'stroke-red-500',
    [ENUM_ACTIONS.ORDER_CREATED]: 'stroke-sky-500',
    [ENUM_ACTIONS.UNATTENDED_TABLE_ORDER_CREATED]: 'stroke-warning',
    [ENUM_ACTIONS.ORDER_ACCEPTED]: 'stroke-green-500',
    [ENUM_ACTIONS.ORDER_CANCELLED]: 'stroke-red-500',
    [ENUM_ACTIONS.ORDER_IN_PROGRESS]: 'stroke-yellow-500',
    [ENUM_ACTIONS.ORDER_PENDING]: 'stroke-yellow-400',
    [ENUM_ACTIONS.ORDER_READY]: 'stroke-green-500',
    [ENUM_ACTIONS.ORDER_PAID]: 'stroke-green-500',
    [ENUM_ACTIONS.ORDER_REFUNDED]: 'stroke-teal-500',
    [ENUM_ACTIONS.TABLE_ASSIGNED]: 'stroke-green-600',
    [ENUM_ACTIONS.TABLE_REMOVED]: 'stroke-red-500',
    [ENUM_ACTIONS.TABLE_CHANGED]: 'stroke-sky-500',
    [ENUM_ACTIONS.TABLE_CHANGED_NEW_ASSIGNED_WAITER]: 'stroke-red-500',
    [ENUM_ACTIONS.TABLE_CHANGED_FROM_ANOTHER_WAITER]: 'stroke-warning',
    [ENUM_ACTIONS.UNATTENDED_TABLE_ASSIGNED]: 'stroke-green-600',
    [ENUM_ACTIONS.UNATTENDED_TABLE_REMOVED]: 'stroke-red-500',
    [ENUM_ACTIONS.UNATTENDED_TABLE_CHANGED]: 'stroke-sky-500',
    [ENUM_ACTIONS.ATTENDED_TABLE_CHANGED_TO_UNATTENDED]: 'stroke-red-500',
    [ENUM_ACTIONS.UNATTENDED_TABLE_CHANGED_TO_UNATTENDED]: 'stroke-warning',
    [ENUM_ACTIONS.UNATTENDED_TABLE_CHANGED_TO_ATTENDED]: 'stroke-sky-500',
    [ENUM_ACTIONS.UNATTENDED_TABLE]: 'stroke-red-500',
    [ENUM_ACTIONS.WAITER_RESERVATION_COMPLETED]: 'stroke-green-500',
    [ENUM_ACTIONS.RESERVATION_COMPLETED]: 'stroke-green-500',
    [ENUM_ACTIONS.ITEM_HIGH_THRESHOLD]: 'stroke-info',
    [ENUM_ACTIONS.ITEM_LOW_THRESHOLD]: 'stroke-warning',
    [ENUM_ACTIONS.ITEM_ZERO_THRESHOLD]: 'stroke-error',
}

const ENUM_TYPE = {
    RESERVATION: 'RESERVATION',
    ORDER: 'ORDER',
    CUSTOMER: 'CUSTOMER',
    BROADCAST: 'BROADCAST',
    BROADCAST_HOST: 'BROADCAST_HOST',
    BROADCAST_KITCHEN_STAFF: 'BROADCAST_KITCHEN_STAFF',
    BROADCAST_BARMAN: 'BROADCAST_BARMAN',
    BROADCAST_WAITER: 'BROADCAST_WAITER',
    BROADCAST_MENU_ITEM: 'BROADCAST_MENU_ITEM',
    BROADCAST_OPTION_ITEM: 'BROADCAST_OPTION_ITEM',
}

const PAGE_MODES = {
    RESERVATIONS: 'reservations',
    ORDERS: 'orders',
    CUSTOMER: 'customer',
    ADMIN: 'admin',
}

const NOTIFICATION_STATUS = {
    UNREAD: 'UNREAD',
    READ: 'READ',
}

const BUCKET_LIST = {
    CATEGORIES_IMAGES: 'categories_images',
    MENU_ITEMS_IMAGES: 'menu_items_images',
    MENU_IMAGES: 'menu_images',
    EVENT_IMAGES: 'event_images',
    VENUE_IMAGES: 'venue_images',
}

const BUCKET_URL = {
    PUBLIC: 'storage/v1/object/public',
}
const MEASURING_UNITS = {
    GRAMS: 'g',
    MILLIGRAMS: 'mg',
    KILOGRAM: 'kg',
    OUNCE: 'oz',
    POUND: 'lb',
    CALORIES: 'kcal',
    SERVING: 'serving',
    SLICE: 'slice',
    PIECE: 'piece',
    PACKET: 'packet',
    CONTAINER: 'container',
    BOTTLE: 'bottle',
    MILLILITER: 'ml',
    LITER: 'l',
    TEASPOON: 'tsp',
    TABLESPOON: 'tbsp',
    FLUID_OUNCE: 'fl oz',
    CUP: 'cup',
    PINT: 'pt',
    QUART: 'qt',
    GALLON: 'gal',
}
/* 0 - Sunday to 6 - Saturday */
const DAYS_IN_NUMBER = [0, 1, 2, 3, 4, 5, 6]

const CURRENCIES = [
    { id: 'BGN', label: 'Bulgarian Lev', symbol: 'лв.' },
    { id: 'USD', label: 'US Dollar', symbol: '$' },
    { id: 'EUR', label: 'Euro', symbol: '€' },
]

const REFUND_REASONS = {
    DUPLICATE: 'duplicate',
    FRAUDULENT: 'fraudulent',
    REQUESTED_BY_CUSTOMER: 'requested_by_customer',
}
const DELTA_TYPES = {
    INCREASE: 'increase',
    DECREASE: 'decrease',
    NEUTRAL: 'neutral',
    MODERATE_INCREASE: 'moderate_increase',
}
const PERIOD_RANGES = {
    YESTERDAY: 'yesterday',
    LAST_SEVEN_DAYS: 'last_7_days',
    LAST_THIRTY_DAYS: 'last_30_days',
    MONTH_TO_DATE: 'month_to_date',
    YEAR_TO_DATE: 'year_to_date',
}
const PERIOD_RANGE_TYPE = {
    CONSECUTIVE: 'consecutive',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
}

const ACTION_TYPES = {
    INIT_DATA: 'INIT_DATA',
    INIT_RESERVATIONS_DASHBOARD_DATA: 'INIT_RESERVATIONS_DASHBOARD_DATA',
    INIT_ORDERS_DASHBOARD_DATA: 'INIT_ORDERS_DASHBOARD_DATA',
    INSERT_ORDER: 'INSERT_ORDER',
    UPDATE_ORDER: 'UPDATE_ORDER',
    DELETE_ORDER: 'DELETE_ORDER',
    INSERT_RESERVATION: 'INSERT_RESERVATION',
    UPDATE_RESERVATION: 'UPDATE_RESERVATION',
    DELETE_RESERVATION: 'DELETE_RESERVATION',
    DELETE_RESERVATIONS: 'DELETE_RESERVATIONS',
    INSERT_TABLE: 'INSERT_TABLE',
    DELETE_TABLE: 'DELETE_TABLE',
    UPDATE_TABLE: 'UPDATE_TABLE',
    COPY_DEFAULT_TABLE_LAYOUT: 'COPY_DEFAULT_TABLE_LAYOUT',
    ASSIGN_TABLE_TO_WAITER: 'ASSIGN_TABLE_TO_WAITER',
    INSERT_FLOOR: 'INSERT_FLOOR',
    UPDATE_FLOOR: 'UPDATE_FLOOR',
    DELETE_FLOOR: 'DELETE_FLOOR',
    DELETE_SECTION: 'DELETE_SECTION',
    INSERT_SECTION: 'INSERT_SECTION',
    UPDATE_SECTION: 'UPDATE_SECTION',
    UPDATE_CUSTOMER_TABLE: 'UPDATE_CUSTOMER_TABLE',
    UPDATE_CUSTOMER_RESERVATION: 'UPDATE_CUSTOMER_RESERVATION',
    DELETE_CUSTOMER_RESERVATION: 'DELETE_CUSTOMER_RESERVATION',
    GET_CUSTOMER_ORDERS: 'GET_CUSTOMER_ORDERS',
    ADD_ORDER: 'ADD_ORDER',
    UPDATE_CUSTOMER_ORDER: 'UPDATE_CUSTOMER_ORDER',
    UPDATE_RESERVATION_ACTION: 'UPDATE_RESERVATION_ACTION',
    DELETE_CUSTOMER_ORDER: 'DELETE_CUSTOMER_ORDER',
    SET_EVENTS: 'SET_EVENTS',
    SET_SELECTED_EVENTS: 'SET_SELECTED_EVENTS',
    SET_SELECTED_TABLE: 'SET_SELECTED_TABLE',
    SET_TIME_PREFERENCES: 'SET_TIME_PREFERENCES',
    INIT_BOOKING_PAGE_DATA: 'INIT_BOOKING_PAGE_DATA',
    RESET_BOOKING_STATE: 'RESET_BOOKING_STATE',
}

const REAL_TIME_ACTION_TYPES = {
    RESERVATIONS_INSERT: 'RESERVATIONS_INSERT',
    RESERVATIONS_UPDATE: 'RESERVATIONS_UPDATE',
    RESERVATIONS_DELETE: 'RESERVATIONS_DELETE',
    ORDERS_INSERT: 'ORDERS_INSERT',
    ORDERS_UPDATE: 'ORDERS_UPDATE',
    ORDERS_DELETE: 'ORDERS_DELETE',
    VENUE_TABLES_INSERT: 'VENUE_TABLES_INSERT',
    VENUE_TABLES_UPDATE: 'VENUE_TABLES_UPDATE',
    VENUE_TABLES_DELETE: 'VENUE_TABLES_DELETE',
    NOTIFICATIONS_INSERT: 'NOTIFICATIONS_INSERT',
    NOTIFICATIONS_UPDATE: 'NOTIFICATIONS_UPDATE',
    NOTIFICATIONS_DELETE: 'NOTIFICATIONS_DELETE',
    FLOORS_LOCALIZATION_INSERT: 'FLOORS_LOCALIZATION_INSERT',
    FLOORS_LOCALIZATION_UPDATE: 'FLOORS_LOCALIZATION_UPDATE',
    FLOORS_LOCALIZATION_DELETE: 'FLOORS_LOCALIZATION_DELETE',
    SECTIONS_LOCALIZATION_INSERT: 'SECTIONS_LOCALIZATION_INSERT',
    SECTIONS_LOCALIZATION_UPDATE: 'SECTIONS_LOCALIZATION_UPDATE',
    SECTIONS_LOCALIZATION_DELETE: 'SECTIONS_LOCALIZATION_DELETE',
}

const TABLE_TYPES = {
    DYNAMIC: 'dynamic',
    STATIC: 'static',
}

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB
const ALLOWED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp']
const FILE_NAME_REGEX = /^[a-zA-Z0-9_.\- ()]+$/

export const MILLISECONDS_IN_MINUTE = 60000 // 60 seconds * 1000 milliseconds
export const PIXELS_PER_HOUR = 300
export const MINUTES_PER_GRID = 15
export const GRID_SIZE = PIXELS_PER_HOUR / (60 / MINUTES_PER_GRID)

export const ROW_HEIGHT = GRID_SIZE
export const ROW_WIDTH = GRID_SIZE
export const ROW_GAP = 10 // 1px gap between rows

const DURATION_VALUES = {
    ONE_HOUR: 'one_hour',
    TWO_HOURS: 'two_hours',
    THREE_HOURS: 'three_hours',
    FOUR_HOURS: 'four_hours',
    FIVE_HOURS: 'five_hours',
    UNTIL_CLOSE: 'until_close'
} as const;

const DURATION_MINUTES = {
    [DURATION_VALUES.ONE_HOUR]: 60,
    [DURATION_VALUES.TWO_HOURS]: 120,
    [DURATION_VALUES.THREE_HOURS]: 180,
    [DURATION_VALUES.FOUR_HOURS]: 240,
    [DURATION_VALUES.FIVE_HOURS]: 300,
    [DURATION_VALUES.UNTIL_CLOSE]: null,
} as const;

export default REAL_TIME_ACTION_TYPES

export {
    MAX_FILE_SIZE,
    ALLOWED_FILE_TYPES,
    FILE_NAME_REGEX,
    STATUS_CONSTANTS,
    ORDER_STATUS_CONSTANTS,
    TABS_CONSTANTS,
    MODE_CONSTANTS,
    LOCATION_CONSTANTS,
    NOTIFICATION_TYPE,
    NOTIFICATION_STATUS,
    ENUM_ROLES,
    ENUM_PATHNAMES,
    ENUM_ACTIONS,
    PAGE_MODES,
    ENUM_TYPE,
    NOTIFICATIONS_COMPONENT_ICON_COLORS,
    BUCKET_LIST,
    BUCKET_URL,
    MEASURING_UNITS,
    DAYS_IN_NUMBER,
    CURRENCIES,
    REFUND_REASONS,
    RECEIPT_STATUS_CONSTANTS,
    REFUND_FAILED_REASONS,
    DELTA_TYPES,
    PERIOD_RANGES,
    PERIOD_RANGE_TYPE,
    ACTION_TYPES,
    REAL_TIME_ACTION_TYPES,
    TABLE_TYPES,
    DURATION_VALUES,
    DURATION_MINUTES,
}
